import React, { useEffect, useState } from 'react';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
import LeftNavButton from './LeftNavButton';
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ApiUrl, GetQuoteId, StripePublicKey, StripesandboxPublicKey } from '../../utils/utils';
import axios from 'axios';
import LoaderImg from "../../assets/images/loader.gif";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ExpressCheckoutElement, } from "@stripe/react-stripe-js";
import ScriptTag from 'react-script-tag';
import './Payment.css';

import CheckoutForm from "./CheckoutForm.js";



function Payments() {

    const QuoteId = GetQuoteId();
    const [isLoader, setisLoader] = useState(false);
    const [isdeposit, setIsdeposit] = useState(false);
    const [isstage, setIsstage] = useState(false);
    const [isfinal, setisFinal] = useState(false);
    const [isPayoption, setisPayoption] = useState(false);
    const [isShowpayment, setisShowpayment] = useState(false);
    const [isPaymentintent, setisPaymentintent] = useState(false);
    const [isPayment, SetisPayment] = useState(false);
    const [remainingAmt, setRemainingAmt] = useState(0.0);
    const [pmtAmt, setpmtAmt] = useState(0.0);
    const [clientSecratecode, setclientSecratecode] = useState('');
    const [paymentamount, setPaymentAmout] = useState('');
    const ref = React.useRef(null);

    const [qouteData, setQouteData] = useState('');
    const stripe = loadStripe(
        StripePublicKey()
    );


    const GetQuote = () => {
        setisLoader(true);
        const data = {};
        data.QuoteId = QuoteId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`
        };
        axios(option)
            .then((e) => {
                setisLoader(false);
                setQouteData(e?.data?.data);
                const dt = e?.data?.data?.quotePaymentInfo;
                if (e?.data?.data?.quoteContractInfoModel?.isSent == true) {
                    SetisPayment(true);
                }

                if (dt?.isDepositAmtPayed == false && dt?.isStageAmtPayed == false && dt?.isFixedAmtPayed == false) {
                    setIsdeposit(true);
                    setIsstage(false);
                    setisFinal(false);
                    let amtTotal = dt?.depositPaymentAmt + dt?.stagePaymentAmt + dt?.balancePaymentAmt;
                    setRemainingAmt(amtTotal);
                }

                if (dt?.isDepositAmtPayed == true) {
                    setIsstage(true);
                    setisFinal(false);
                    let amtTotal = dt?.stagePaymentAmt + dt?.balancePaymentAmt;
                    setRemainingAmt(amtTotal);
                }

                if (dt?.isStageAmtPayed == true) {
                    setIsstage(false);
                    setisFinal(true);
                    let amtTotal = dt?.balancePaymentAmt;
                    setRemainingAmt(amtTotal);
                }

                if (dt?.isDepositAmtPayed == true && dt?.isStageAmtPayed == true && dt?.isFixedAmtPayed == true) {
                    setisFinal(false);
                    setisPayoption(true);
                    setRemainingAmt(0.0);
                }
                let pamt = 0.0;
                if (dt?.isDepositAmtPayed == true) {
                    pamt = dt.stagePaymentAmt;

                }

                if (dt?.isStageAmtPayed == true) {
                    pamt = dt.balancePaymentAmt;
                }
                if (dt?.isFixedAmtPayed == true) {
                    pamt = dt.balancePaymentAmt;
                }

                if (dt?.isDepositAmtPayed == false && dt?.isStageAmtPayed == false && dt?.isFixedAmtPayed == false) {

                    pamt = dt.depositPaymentAmt;
                }
                setpmtAmt(pamt);
            })
            .catch((err) => {
            })
    }

    const payMoney = async (pamt) => {

        const data = {};
        data.QuoteId = QuoteId;
        data.Amount = pmtAmt;
        data.Currency = 'gbp';
        data.Email = qouteData?.customerEmail;
        data.Name = qouteData?.customerName;

        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/stripe/createPaymentIntent`
        };
        await axios(option)
            .then((e) => {

                setclientSecratecode(e?.data);
            })
            .catch((err) => {

                return 'err';
            });
    };

    const showPayment = (vl) => {
        setisShowpayment(true);
        payMoney();
        if (vl == '1') {

            setisPaymentintent(true);

        } else {
            setisPaymentintent(false);
        }



    }

    const closePaymentModal = () => {
        setisShowpayment(false);
        window.location.reload();
    }

    const onConfirm = async (event) => {
        if (!stripe) {
            // Stripe.js hasn't loaded yet.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        // Confirm the PaymentIntent using the details collected by the Express Checkout Element
        const { error } = await stripe.confirmPayment({
            // `elements` instance used to create the Express Checkout Element
            Elements,
            // `clientSecret` from the created PaymentIntent
            clientSecratecode,
            confirmParams: {
                //return_url: 'https://example.com/order/123/complete',
            },
        });

        if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer (for example, payment details incomplete)
            alert(error.message);
        } else {
            // The payment UI automatically closes with a success animation.
            // Your customer is redirected to your `return_url`.
        }
    };

    useEffect(() => {
        GetQuote();
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
        // AOS.init({ duration: 2000 });

        // const script = document.createElement("script");

        // script.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        // script.async = true;

        // document.body.appendChild(script);


    }, []);
    const NextArrow = ({ onClick, className }) => (
        <div onClick={onClick} className="slick-prev"><img src={PagesSvg.CaArrowLeftIconSvg} alt="img" className="img-fluid" id="prv-img" /></div>
    );

    const PrevArrow = ({ onClick, className }) => (<div onClick={onClick} className="slick-next"><img src={PagesSvg.CaArrowLeftIconSvg} alt="img" className="img-fluid" id="nxt-img" /></div>
    );



    var settings = {
        arrows: true,
        infinite: true,
        speed: 500,
        vertical: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <NextArrow />,
        nextArrow: <PrevArrow />
    };

    const numberWithCommas = (x) => {
        var val = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return val;
    }   

    return (<div className="container-fluid">
        <div className="row">
            <div className="col-md-3 col-lg-2 px-0">
                <Sidebar />
            </div>
            <div className="col-md-9 col-lg-10 payment-page mainbg_payment position-relative">
                <div className="main-content align-items-start py-5">
                  <div className="bg-image"> <img src={qouteData?.coverImagePath} alt="img" className="bg-img img-fluid"/></div> 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-6">
                                <div className="heading-section">

                                    <h1>Payments</h1>

                                </div>
                            </div>
                            <div className="col-md-4 col-6 position-relative">
                                <div className="popup-cta-btn print-info-btn ms-auto">
                                    <div className="info-btn-sec">
                                        <a className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle2" role="button"><img src={PagesSvg.InfoSvg} alt="icon" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row my-5">
                            {isPayment && <div className="col-lg-12 col-md-12 pe-lg-5">
                                <div className="row">
                                    {qouteData && <div className="col-lg-8">
                                        <div className="payment-page-content">
                                            <div className="payment-count-area">
                                                <p> Please Pay the highlighted amount. </p>

                                                <div className="payment-stages-sec">
                                                    <table className="payment-stages">
                                                        <tr className={`${(isdeposit ? 'active' : '')}`}>
                                                            <td>Deposit Payment:</td>
                                                            <td>£{numberWithCommas(qouteData?.quotePaymentInfo?.depositPaymentAmt.toFixed(2))}<span> Including VAT</span></td>
                                                            {/* <td>{qouteData?.quotePaymentInfo?.isDepositAmtPayed ? '' : 'Outstanding'}</td> */}
                                                        </tr>
                                                        <tr className={`${(isstage ? 'active' : '')}`}>
                                                            <td>Stage payment:</td>
                                                            <td>£{numberWithCommas(qouteData?.quotePaymentInfo?.stagePaymentAmt.toFixed(2))}<span> Including VAT</span></td>
                                                            {/* <td>{qouteData?.quotePaymentInfo?.isStageAmtPayed ? '' : 'Outstanding'}</td> */}
                                                        </tr>
                                                        <tr className={`${(isfinal ? 'active' : '')}`}>
                                                            <td>Final payment:</td>
                                                            <td>£{numberWithCommas(qouteData?.quotePaymentInfo?.balancePaymentAmt.toFixed(2))}<span> Including VAT</span></td>
                                                            {/* <td>{qouteData?.quotePaymentInfo?.isFixedAmtPayed ? '' : 'Outstanding'}</td> */}
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {qouteData && <div className="col-lg-4 d-flex align-items-end justify-content-center">
                                        <div className="total-price-box">
                                            <h3>
                                                <span>Quote Price</span><br />

                                                £{numberWithCommas(qouteData?.quotePaymentInfo?.totalGrossAmtAfterDiscount.toFixed(2))} <br/> + VAT 
                                                {/*<span>-{(qouteData?.discount != 0 ? qouteData?.discount+'% (Discount)' : '')}</span>*/}
                                            </h3>
                                            <h3>
                                                <span>Remaining</span><br />
                                                £{numberWithCommas(remainingAmt.toFixed(2))}  <br/>  

                                            </h3>
                                        </div>
                                    </div>}
                                </div>
                                {!isPayoption && <div className="row my-5">
                                    <div className="col-lg-6 col-md-10 col-sm-12 col-xs-12 m-auto">

                                        <div className='info_payment_glazing text-center'>
                                            <h2 className='payment_lable_top'> <span>  <img src={require('../../assets/images/payment_lock.png')} alt="img" className="lock_payment" /> </span> Payment </h2> 
                                            <p className='yg_number_txt'> If you wish to pay by Bank transfer, please use your YG number as reference. </p>
                                            <p> Barclays Bank Plc </p>
                                            <p> YES Glazing Solutions </p>
                                            <div className='d-flex acount_flex_text'>
                                            <p> Sort code: 20-14-33 </p>
                                            <p> Account number: 03413292 </p>
                                            </div>
                                            <p> Or pay by card </p>
                                        </div>
                                        <div className="card-sec">
                                            <a onClick={() => showPayment(1)} role="button">
                                                <h3> Card Payment</h3>
                                                <img src={PagesSvg.CardSvg} alt="icon" className="img-fluid" />
                                            </a>
                                            </div>
                                            <div className='d-flex bottom_payment_gpay'>
                                             <p> We accept the following payment methods: </p>
                                             <div className='d-flex icons_gpay'>
                                             <img src={require('../../assets/images/payment_visas.png')} alt="img" className=" visacardvs" />
                                             <img src={require('../../assets/images/payment_masters.png')} alt="img" className="mastercard_visa" />
                                             <img src={require('../../assets/images/payment_apples.png')} alt="img" className="applepayment" />
                                             <img src={require('../../assets/images/payment_gpays.png')} alt="img" className="gpaypaymentvs" />
                                            </div>
                                             
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 col-sm-6">
                                        <div className="card-sec">
                                            <a onClick={() => showPayment(1)} role="button">
                                                <h3>International<br /> Bank Transfer</h3>
                                                <img src={PagesSvg.CardtransferIconSvg} alt="icon" className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="card-sec">
                                            <a onClick={() => showPayment(2)} role="button">
                                                <h3>Apply Pay</h3>
                                                <img src={PagesSvg.ApplePayIconSvg} alt="icon" className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="card-sec">
                                            <a onClick={() => showPayment(2)} role="button">
                                                <h3>Google Pay</h3>
                                                <img src={PagesSvg.GooglePayIconSvg} alt="icon" className="img-fluid" />
                                            </a>
                                        </div>
                                    </div> */}
                                    {isLoader && <div className="loader-img-outer"><img src={LoaderImg} className="loader_img_cont" /> </div>}


                                </div>}

                                {isPayoption && <div className='payment_success'>
                                    <h3>Your payment has been completed.</h3>
                                </div>}
                            </div>}

                            {!isPayment && <div className="col-lg-12 col-md-12 pe-lg-5">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="payment-page-content">
                                            <div className="payment-count-area">
                                                <h3> Thank you for approving your Quotation. <br /> {qouteData?.salesPersonName} will be in touch shortly to discuss your contract. </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {/* <div className="col-lg-3 col-md-4 ps-md-5">
                                <div className="customer-review-sec">
                                    <h3>Customer Review</h3>
                                    <div className="vertical-carousel">
                                        <Slider {...settings}>
                                            <div >
                                                <img src={require('../../assets/images/cr01.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr02.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr03.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr04.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr05.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr06.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr07.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr01.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>

                                            <div >
                                                <img src={require('../../assets/images/cr02.png')} alt="img" className="review-video img-fluid slideh" />
                                            </div>


                                        </Slider>

                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-sm-12">
                                <div ref={ref} className="trustpilot-widget" data-locale="en-GB" data-template-id="5613c9cde69ddc09340c6beb" data-businessunit-id="59c122d30000ff0005ab87fe" data-style-height="100%" data-style-width="100%" data-theme="light">
                                    <a href="https://uk.trustpilot.com/review/yesglazing.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>




        </div>
        <div className={`modal blur-overlay fade payment-popup-overlay ${!isShowpayment ? 'd-none' : ''}`} id="paymentToggle" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="blk-logo text-center">
                                    <img src={require('../../assets/images/ygs_logo_black.png')} alt="img" className="logoforpopup img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">


                                {clientSecratecode != '' && <Elements stripe={stripe} options={{
                                    // passing the client secret obtained from the server
                                    clientSecret: clientSecratecode,
                                }}>
                                    <CheckoutForm />
                                </Elements>}

                                <button type="button" className="btn-close" onClick={() => closePaymentModal()}></button>


                            </div>
                        </div>


                        {/*!isPaymentintent &&  <div className="row">
                          <div className="col-12">
                         
                          {pmtAmt != 0.0 && <Elements stripe={stripe} options={{
                              mode: 'payment',
                              amount: pmtAmt,
                              currency: 'gbp'
                            }}>
                             <ExpressCheckoutElement onConfirm={onConfirm} />
                            </Elements> }

                            

                            <button type="button" className="btn-close" onClick={() => closePaymentModal()}></button>


                          </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </div>
        <div className="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row align-items-center">
                            <div className="col-12">

                                <h5>Stonly.com <br />Support info</h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}
export default Payments;