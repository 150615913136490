import {ReactDOM,createRoot} from "react-dom/client";
import {StrictMode} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Start from "./pages/Start/Start";
import Team from "./pages/Team/Team";
import Glass from "./pages/Glass/Glass";
import Project from "./pages/Project/Project";
import Review from "./pages/Review/Review";
import Makerequest from "./pages/Makerequest/Makerequest";
import Terms from "./pages/Terms/Terms";
import Payments from "./pages/Payments/Payments";
import Progress from "./pages/Progress/Progress";
import About from "./pages/About/About";
import './assets/css/style.css';
import { AnimatePresence } from "framer-motion";
export default function App() {
  return (
    <BrowserRouter>
    <AnimatePresence wait>
      <Routes>
       <Route path="/"  element={<Start />} />
       <Route path="/team"  element={<Team />} />
       <Route path="/glass"  element={<Glass />} />
       <Route path="/project"  element={<Project />} />
       <Route path="/review"  element={<Review />} />
       <Route path="/make-request"  element={<Makerequest />} />
       <Route path="/terms&Sign"  element={<Terms />} />
       <Route path="/payments"  element={<Payments />} />
       <Route path="/progress"  element={<Progress />} />
       <Route path="/about"  element={<About />} />
      </Routes>
    </AnimatePresence> 
    </BrowserRouter>
  );
}
 
const root = document.getElementById('root');
const rdoot = createRoot(root);
rdoot.render(
  <StrictMode>
    <App />
  </StrictMode>,
);