import React, { Component, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import EstimatesecSvg from "../Constant/EstimatesecSvg";
import background from "../../assets/images/black_close_modal.svg";
import disableProductImg from "../../assets/images/product_dis.svg";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Project.css';
import { Outlet, Link, NavLink } from "react-router-dom";
import { ApiUrl, GetQuoteId } from '../../utils/utils';
import axios from 'axios';
import moment from 'moment/moment';
import LoaderImg from "../../assets/images/loader.gif";
import PagesSvg from "../Constant/PagesSvg";
import * as animationData from '../../assets/js/7280801.json'
import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

const Project = () => {

    let navigate = useNavigate();
    const [activeProduct, setActiveProduct] = useState(null);
    const QuoteId = GetQuoteId();
    const [welcomeModal, setWelcomeModal] = useState(false);
    const [isModalMinimise, setIsModalMinimize] = useState(false);
    const [enhImageModalLabel, setenhImageModalLabel] = useState(false);
    const [cpactive, setCpactive] = useState(false);
    const [qouteData, setQouteData] = useState('');
    const [enhImgesArr, setenhImgesArr] = useState([]);
    const [popData, setPopData] = useState([]);
    const [TermsConditionData, setTermsConditionData] = useState([]);
    const [addedEnsPrd, setaddedEnsPrd] = useState([]);
    const [videos, setVideos] = useState([]);
    const [infos, setInfos] = useState([]);
    const [enhqty, setEnhQty] = useState([]);
    const dateAdded = moment(qouteData?.createdOn)?.format("DD/MM/YY");
    const [active, setActive] = useState(null)
    const [pactive, setPactive] = useState(null)
    const [hactive, setHactive] = useState(null)
    const [enhAddedamt, setEnhAddedamt] = useState(0)
    const [handelAddedamt, setHandelAddedamt] = useState(0)
    const [isLoader, setisLoader] = useState(false);
    const [raddedEnsPrd, setraddedEnsPrd] = useState([]);
    const [addedHandel, setaddedHandel] = useState([]);
    const [enhamtArr, setenhamtArr] = useState([]);
    const [enhamtSplitArr, setenhamtSplitArr] = useState([]);
    const [handelamtArr, seteHanelamtArr] = useState([]);
    const [prdiamge, setPrdImage] = useState('');
    const [imgprdGallery, setimgprdGallery] = useState('');
    const [handelImage, sethandelImage] = useState('');
    const [isActive, setIsActive] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: true,
        loop: false,
        autoplay: false,
        animateOut: true,
        animateIn: true,
        autoplayHoverPause: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            480: {
                items: 2,
                margin: 15,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 2,
            }
        },
    };



    let location = useLocation();

    useEffect(() => {
        GetQuote();
        AOS.init({ duration: 2000 });
        let pop_status = localStorage.getItem('pop_status');
        if (pop_status) {
            setWelcomeModal(true)
        }

        let prevMode = localStorage.getItem('previewMode');
        if (prevMode == 'preview') {
            setCpactive(true);
        }




    }, [location])


    const slideToProd = (pId) => {
        const element = document.getElementById(pId + '_mainprd_sec');
        setActive(pId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    const toggleSec = () => {
        $(".estimate-content-section").toggleClass("collapsible-shutter");

        setTimeout(
            function () {
                return navigate("/review");
            }
                .bind(this),
            100
        );
    }

    const closeModal = () => {
        $("#welcome-modal").css('display', 'none');
    }

    const addClassOnScroll = function () {
        var windowTop = $(window).scrollTop();

        $('.prd_sect_each').each(function (index, elem) {
            var offsetTop = $(elem).offset().top;
            var outerHeight = $(this).outerHeight(true);
            if (windowTop > (offsetTop - 1000) && windowTop < (offsetTop + outerHeight)) {
                var elemId = $(elem).attr('pid');
                setActiveProduct(elemId);
            }

        });
    };

    const showenhanImages = (images) => {
        let imgsAr = [];
        for (var i = 0; i < images.length; i++) {
            imgsAr.push(images[i]);
        }
        setenhImgesArr(imgsAr);
        setIsModalMinimize(!isModalMinimise)
        setenhImageModalLabel(true)
    }

    const showPopData = (products) => {
        setPopData(products);
        setIsModalMinimize(!isModalMinimise)
        setenhImageModalLabel(true)
    }
    const showTermConditionData = (termcondition) => {
        setTermsConditionData(termcondition);
        setIsModalMinimize(!isModalMinimise)
        setenhImageModalLabel(true)
    }
    const showPopImages = (images) => {
        let imgsAr = [];
        for (var i = 0; i < images.length; i++) {
            imgsAr.push(images[i]);
        }
        setenhImgesArr(imgsAr);
        setIsModalMinimize(!isModalMinimise)
        setenhImageModalLabel(true)
    }


    const showPopVideos = (videos) => {

        setVideos(videos);
    }
    const showPopInfo = (info) => {

        let imgsAr = [];
        if (info.length > 0) {
            setInfos(info);
        }

    }

    const GetQuote = () => {
        setisLoader(true);
        const data = {};
        data.QuoteId = QuoteId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`
        };
        axios(option)
            .then((e) => {
                setisLoader(false);
                console.log(e?.data?.data)
                setQouteData(e?.data?.data);
                setTimeout(() => {
                    $('.prd_sect_each:first').addClass('active');
                }, 1000);
                let pop_status = localStorage.getItem('pop_status');
                if (!pop_status) {

                    localStorage.setItem('pop_status', 1);
                }

                if (location.state != null) {
                    const pId = location.state.product_id;
                    setTimeout(
                        function () {
                            slideToProd(pId);
                        }
                            .bind(this),
                        500
                    );

                }



                let prervVal = [];
                let prervVald = [];
                let prevVal = [];
                let prehandelVal = [];

                prevVal = e?.data?.data
                if (prevVal.products) {
                    let prdStat = prevVal.products;
                    let preEnamot = enhAddedamt;
                    let preEnamotd = 0;
                    let enhamtArrAmt = [];
                    let enhamtsplitArrAmt = [];
                    let enhamtHandelAmt = [];
                    let preHanamotd = 0;
                    var z = 0;
                    for (var i = 0; i < prdStat.length; i++) {
                        if (prevVal?.status != 'V') {
                            let endMdt = 0;
                            for (var j = 0; j < prdStat[i].productEnhancementArr.length; j++) {
                                if (prdStat[i].productEnhancementArr[j].status == 'A') {
                                    prervVal.push(prdStat[i].productEnhancementArr[j].enhancementId + '-' + prdStat[i].productEnhancementArr[j].productId);
                                    preEnamotd = preEnamotd + (prdStat[i].productEnhancementArr[j].price * prdStat[i].productEnhancementArr[j].qty);
                                    endMdt = endMdt + prdStat[i].productEnhancementArr[j].price * prdStat[i].productEnhancementArr[j].qty;
                                    enhamtsplitArrAmt[prdStat[i].productEnhancementArr[j].enhancementId + '-' + prdStat[i].productEnhancementArr[j].productId] =
                                        // parseFloat((prdStat[i].productEnhancementArr[j].price * prdStat[i].productEnhancementArr[j].qty).toFixed(2));
                                        (parseFloat(prdStat[i].productEnhancementArr[j].price * prdStat[i].productEnhancementArr[j].qty)).toFixed(2);
                                }
                            }
                            enhamtArrAmt[prdStat[i].quoteProductId] = endMdt;
                            if (prdStat[i].selectedHandleOption.length > 0) {
                                let hndAmt = 0;
                                for (var k = 0; k < prdStat[i].selectedHandleOption.length; k++) {
                                    if (prdStat[i].selectedHandleOption[k].status == 'A') {
                                        prehandelVal.push(prdStat[i].selectedHandleOption[k].productId + '-' + prdStat[i].selectedHandleOption[k].id);
                                        preHanamotd = preHanamotd + (prdStat[i].selectedHandleOption[k].price * prdStat[i].selectedHandleOption[k].qty);
                                        hndAmt = hndAmt + prdStat[i].selectedHandleOption[k].price * prdStat[i].selectedHandleOption[k].qty;

                                    }
                                }
                                enhamtHandelAmt[prdStat[i].quoteProductId] = hndAmt;
                            }
                        }

                    }

                    if (prevVal?.status == 'C') {
                        setCpactive(true);
                    }

                    setHandelAddedamt(preHanamotd);
                    console.log(prehandelVal)
                    setaddedHandel(prehandelVal);
                    setEnhAddedamt(preEnamotd);

                    //console.log(prervVal)
                    setaddedEnsPrd(prervVal);


                    setenhamtArr(enhamtArrAmt);
                    setenhamtSplitArr(enhamtsplitArrAmt);
                    seteHanelamtArr(enhamtHandelAmt);
                }



            })
            .catch((err) => {
            })
    }
    const saveProduct = async (item) => {
        const data = {};
        setisLoader(true);
        let qty = document.getElementById(item?.enhancementId + '-' + item?.productId).value;


        let prevVal = addedEnsPrd;
        let preEnamot = enhAddedamt;
        let preEnharr = enhamtArr;
        let presplitEnharr = enhamtSplitArr;
        if (prevVal.includes(item.enhancementId + '-' + item.productId)) {

            //preEnharr.splice(item.productId, 1);
            const index = prevVal.indexOf(item.enhancementId + '-' + item.productId);
            prevVal.splice(index, 1)
            delete preEnharr[item.productId];
            delete presplitEnharr[item.enhancementId + '-' + item.productId];
            const values = Object.values(presplitEnharr);

            const amt = values.reduce((accumulator, value) => {
                return accumulator + value;
            }, 0);
            setEnhAddedamt(amt);
            data.Status = 'V';
        } else {

            prevVal.push(item.enhancementId + '-' + item.productId);
            data.Status = 'A';
            //let amt = presplitEnharr.reduce((total, obj) => obj.item.enhancementId+'-'+item.productId + total,0)
            const values = Object.values(presplitEnharr);

            const amt = values.reduce((accumulator, value) => {
                return accumulator + value;
            }, 0);

            preEnharr[item.productId] = item.price * qty;
            presplitEnharr[item.enhancementId + '-' + item.productId] = item.price * qty;
            setEnhAddedamt(amt + item.price * qty);
        }

        setaddedEnsPrd([]);
        setaddedEnsPrd(prevVal);
        setenhamtArr(preEnharr);
        setenhamtSplitArr(presplitEnharr);
        setPactive((prevState) => !prevState)


        data.QuoteId = QuoteId;
        data.ProductId = item.productId;
        data.EnhancementId = item.enhancementId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteEnhancement`
        };
        await axios(option)
            .then((e) => {
                GetQuote();
                setisLoader(false);
            })
            .catch((err) => {
            })
    }

    const changeQty = async (item) => {
        let qty = document.getElementById(item?.enhancementId + '-' + item?.productId).value;
        let preEnharr = enhamtArr;
        let presplitEnharr = enhamtSplitArr;
        if (qty != '') {
            if (qty == '0') {
                qty = '1';
                document.getElementById(item?.enhancementId + '-' + item?.productId).value = '1';
            }

            const index = preEnharr.indexOf(item?.productId + '-' + item?.enhancementId);
            delete preEnharr[item.productId];
            delete presplitEnharr[item.enhancementId + '-' + item.productId];
            const values = Object.values(presplitEnharr);
            const amt = values.reduce((accumulator, value) => {
                return accumulator + value;
            }, 0);
            preEnharr[item.productId] = item.price * qty;
            setEnhAddedamt(amt + item.price * qty);

            presplitEnharr[item.enhancementId + '-' + item.productId] = item.price * qty; setenhamtSplitArr(presplitEnharr);
            setenhamtArr(preEnharr);
            const data = {};
            setisLoader(true);
            data.QuoteId = QuoteId;
            data.ProductId = item.productId;
            data.EnhancementId = item.enhancementId;
            data.Qty = qty;
            data.Status = 'A';
            const option = {
                method: 'POST', headers: {
                    "access-control-allow-origin": "*",
                    "content-type": "application/json",
                    "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
                },
                data: data,
                url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteEnhancement`
            };
            await axios(option)
                .then((e) => {
                    setisLoader(false);

                })
                .catch((err) => {
                })
        }

    }


    const changehandelQty = async (item) => {
        let qty = document.getElementById(item?.productId + '-' + item?.id + '_hndl').value;

        if (qty != '') {
            if (qty == '0') {
                qty = '1';
                document.getElementById(item?.id).value = '1';
            }
            let preEnamot = handelAddedamt;
            let prvalll = item.price * item.qty;
            preEnamot = preEnamot - prvalll;
            setHandelAddedamt(preEnamot + (item.price * qty));
            const data = {};
            setisLoader(true);
            data.QuoteId = QuoteId;
            data.HandleOptionId = item?.id;
            data.Qty = qty;
            data.Status = 'A';
            const option = {
                method: 'POST', headers: {
                    "access-control-allow-origin": "*",
                    "content-type": "application/json",
                    "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
                },
                data: data,
                url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteHandleOption`
            };
            await axios(option)
                .then((e) => {
                    setisLoader(false);

                })
                .catch((err) => {
                })
        }

    }

    const saveHandel = (item, price, qty, prdId) => {
        const data = {};
        let qtyd = document.getElementById(prdId + '-' + item + '_hndl').value;
        let prevVal = addedHandel;
        let preEnamot = handelAddedamt;
        setisLoader(true);
        if (prevVal.includes(prdId + '-' + item)) {
            setHandelAddedamt(preEnamot - (price * qty));
            const index = prevVal.indexOf(prdId + '-' + item);
            prevVal.splice(index, 1)
            data.Status = 'V';

        } else {
            prevVal.push(prdId + '-' + item);
            data.Status = 'A';
            setHandelAddedamt(preEnamot + (price * qtyd));
        }

        setaddedHandel([]);
        setaddedHandel(prevVal);

        setHactive((prevState) => !prevState);
        data.QuoteId = QuoteId;
        data.HandleOptionId = item;
        data.Qty = qty;
        data.ProductId = prdId;


        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteHandleOption`
        };
        axios(option)
            .then((e) => {
                setisLoader(false);
                GetQuote();
            })
            .catch((err) => {
            })
    }

    const openHimg = (prdImg) => {
        sethandelImage(prdImg);
    }


    const closeprdImg = () => {
        setimgprdGallery('');
        sethandelImage('');
    }

    const openPrimg = (prdImg) => {
        setPrdImage(prdImg);
    }

    const openGPrimg = (prdImg) => {
        setimgprdGallery(prdImg);
    }


    const numberWithCommas = (x) => {
        var val = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return val;
    }

    const viewMoreClick = event => {
        // 👇️ toggle isActive state on click
        setIsActive(current => !current);
    };

    return (<motion.div
        className="container-fluid glass-page project_slider_main"

    >
        <div className="row">
            <div className="col-md-3 col-lg-2 px-0">
                <Sidebar />
            </div>
            <div className="col-md-9 col-lg-10 position-relative px-0">
                <div className="main-content align-items-start py-5 project_main_content">
                    <div className="bg-image"> <img src={qouteData?.coverImagePath} alt="img" className="bg-img img-fluid" /></div>
                    <div className="container position-relative h-100">
                        <div className="row" data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="200">
                            <div className="col-md-12 col-lg-10" >
                                <div className="estimate-content-section project_vs_left">
                                    <div className="ecs-fixed-header">
                                        <ul className="estimate-heading-list">
                                            <li className="">
                                                <div class="header_vsc">
                                                    <div className="estimate-left-sec top_estimated_vs">
                                                        <h3>{qouteData?.title}</h3>
                                                        <div className="customer_detailss">
                                                            <div className="">
                                                                <p> Name: {qouteData?.customerName}</p>
                                                                <p>Email: {qouteData?.customerEmail}</p>
                                                                <p>Number: {qouteData?.customerMobileNo}</p>
                                                                <p>Site Address: {qouteData?.siteAddress}</p>
                                                            </div>
                                                            <div className='q_number'>
                                                                <h3>{qouteData?.salesForceRefNo}</h3>
                                                                <a onClick={() => showTermConditionData(qouteData?.termAndCondition?.description)} className="btn img-popup-btn" data-bs-toggle="modal" href="#modal-terms-condition" role="button">Terms and Conditions </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="estimate-right-sec project_vs_right text-right">
                                                        <p className='top_price_schroll bottom_total_project'><span>Total : </span> £{numberWithCommas((qouteData?.totalAmt + parseFloat(enhAddedamt) + handelAddedamt).toFixed(2))} + {qouteData?.vat} % VAT </p>
                                                    </div> */}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {qouteData && <div className="ecs-scrolling-description-sec" onScroll={addClassOnScroll} data-aos="fade-down" data-aos-offset="300"
                                        data-aos-easing="ease-in-sine">
                                        <div className='top_heading_schroll'>
                                            <h4> To {qouteData?.termAndCondition?.name} the following items </h4>
                                            <p className='select_product_text'>Please select what products you wish to proceed to complete your sales contract</p>
                                            <h3>View your project</h3>
                                        </div>
                                        {
                                            qouteData?.products?.map((pro, index) => {
                                                return (
                                                    <div key={index} className={`row prd_sect_each ${(pro?.status == 'V' ? 'desabled_product_section' : '')}`} pid={pro?.quoteProductId} id={pro?.quoteProductId + '_mainprd_sec'}>

                                                        <div className='col-md-12 col-lg-8'>
                                                            <div className='row first_active'>
                                                                <div className='col-md-12'>

                                                                    <div className='left_schroll_vs'>
                                                                        <h6> {pro?.productName}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className={`col-md-12 col-lg-12 product_detail_list ${(activeProduct == pro?.productId ? 'product_detail_list_active' : '')}`}>
                                                                    {pro?.lineItems.length > 0 && <div className={`left_detail_product ${(activeProduct == pro?.productId ? '' : '')}`}>
                                                                        <div className="window_ref_sec">

                                                                            <div className='inlie_flex_item'>
                                                                                {
                                                                                    pro?.lineItems?.map((lnItem, index) => {
                                                                                        return (
                                                                                            <div key={index} className='inlie_flex_inner_item'>
                                                                                                <div className='left_wref'>
                                                                                                    <h5 className='ref_window_name'><span> {lnItem.refId} </span> </h5>
                                                                                                </div>
                                                                                                <div className='right_wref'>
                                                                                                    <h5 className='dims_window_ref'><span> {lnItem.dimension=='' ? '---':lnItem.dimension} </span> </h5>
                                                                                                    {lnItem.cost > 0 ?  <h5 className='last_items_total_price'><span>   £{numberWithCommas(lnItem.cost.toFixed(2))} + VAT</span> </h5> : <h5 className='last_items_total_price'><span>---</span> </h5>}                                 
                                                                                                    
                                                                                                    <h5 className='qty_window_ref'><span> x{lnItem.qty} </span> </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </div>

                                                                <div className={`col-md-12 product_specification_list ${(activeProduct == pro?.productId ? 'product_specification_list_active' : '')}`}>

                                                                    {pro?.specificatio != '' && <div className={`specification_products_bottom box ${(activeProduct == pro?.productId ? '' : '')}`}>
                                                                        <h5>Specification</h5>
                                                                        <div dangerouslySetInnerHTML={{ __html: pro?.specification }}></div>
                                                                    </div>}

                                                                    {pro?.productEnhancementArr.length > 0 && <div className={`extra_enhanacments_product ${(activeProduct == pro?.productId ? '' : '')}`}>
                                                                        <h5> Additional Extras / Enhancements </h5>
                                                                        <p> Please select additional extras / enhancements for your product </p>
                                                                        <div className='checkbox_price_outer'>

                                                                            <ul>

                                                                                {
                                                                                    pro?.productEnhancementArr?.map((enh, index) => {
                                                                                        return (
                                                                                            <li key={index} className="checkbox active_buttons">
                                                                                                <div className='check_main_select'>
                                                                                                    <label className="green_check">
                                                                                                        <input disabled={`${(cpactive || pro?.status == 'V' ? 'act' : '')}`} onChange={() => saveProduct(enh)} className={`checkbdox-pop ermConditions active`} type="checkbox" id="check{enh?.enhancementId}" defaultChecked={(addedEnsPrd.includes(enh?.enhancementId + '-' + enh?.productId) ? true : false)} />
                                                                                                        <span className={`checkmark ${(cpactive || pro?.status == 'V' ? 'disabled_checkbox' : '')}`}></span>
                                                                                                    </label>
                                                                                                    <label htmlFor="enh_{enh?.enhancementId}"> {enh?.name}</label>
                                                                                                </div>
                                                                                                <div className='check_disable_qty'>
                                                                                                    <div className='lable_flex1'>
                                                                                                        <h6> Individual Cost: </h6>
                                                                                                        <p className='disable_btn_first'>  £{numberWithCommas(enh?.price.toFixed(2))} + VAT</p>
                                                                                                    </div>
                                                                                                    <div className='lable_flex2'>
                                                                                                        <h6> QTY </h6>
                                                                                                        {(!addedEnsPrd.includes(enh?.enhancementId + '-' + enh?.productId)) &&
                                                                                                            <input disabled="act" type="number" min={1} className="form-control qty_product" defaultValue={(enh?.qty == 0 ? pro?.qty : enh?.qty)} id={enh?.enhancementId + '-' + enh?.productId} />

                                                                                                        }

                                                                                                        {(addedEnsPrd.includes(enh?.enhancementId + '-' + enh?.productId)) &&

                                                                                                            <input disabled={`${(cpactive || pro?.status == 'V' ? 'act' : 'act')}`} type="number" min={1} className="form-control qty_product" defaultValue={(enh?.qty == 0 ? pro?.qty : enh?.qty)} id={enh?.enhancementId + '-' + enh?.productId} />

                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='lable_flex1'>
                                                                                                        <h6 className='hidden_trickle_text'> Per trickle vent </h6>
                                                                                                        <p className='perun_product'>{enh?.perUnit}</p>
                                                                                                    </div>

                                                                                                    {(addedEnsPrd.includes(enh?.enhancementId + '-' + enh?.productId)) &&
                                                                                                        <div className='lable_flex3'>
                                                                                                            <h6>  Total Cost: </h6>
                                                                                                            <p className='disable_btn_first last_total_cost'> £{numberWithCommas(enhamtSplitArr[enh?.enhancementId + '-' + enh?.productId])} + VAT</p>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {(!addedEnsPrd.includes(enh?.enhancementId + '-' + enh?.productId)) &&
                                                                                                        <div className='lable_flex3'>
                                                                                                            <h6>  Total Cost: </h6>
                                                                                                            <p className='disable_btn_first last_total_cost'> </p>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }


                                                                            </ul>
                                                                        </div>
                                                                    </div>}

                                                                    {index == qouteData?.products.length - 1 && qouteData?.notes != '' && <div className='outer_notes_main'>
                                                                        <div className='Notes_to_customer specification_products_bottom'>
                                                                            <h5> Notes </h5>
                                                                            <div dangerouslySetInnerHTML={{ __html: qouteData?.notes }}></div>
                                                                        </div>
                                                                    </div>}

                                                                    {/* <div className='show_hide_data'>
                                                                                  
                                                                                  <a href="javascript:void(0)" onClick={viewMoreClick}>{isActive ? 'View more' :'Hide'} </a>  
                                                                                </div> */}


                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className={isActive ? 'col-md-12 col-lg-4' : 'col-md-12 col-lg-4'}>
                                                            <div className='right_schroll_vs'>



                                                                <p className='top_price_schroll'> £{numberWithCommas(((pro?.totalAmt) + (enhamtArr[pro?.quoteProductId] ? enhamtArr[pro?.quoteProductId] : 0) + (handelamtArr[pro?.quoteProductId] ? handelamtArr[pro?.quoteProductId] : 0)).toFixed(2))} + VAT </p>

                                                                <div className={`col-md-12 col-lg-12 product_banner_sec ${(activeProduct == pro?.quoteProductId ? 'product_banner_sec_active' : '')}`} >
                                                                    <div className={`right_banner_product ${(activeProduct == pro?.quoteProductId ? '' : '')}`} >
                                                                        {qouteData && pro?.status == 'V' && <div className='img_diable_pro_Sec'><img src={disableProductImg} alt="product_banner_SF55" className="img-fluid" /></div>}

                                                                        {
                                                                            pro?.coverImagePath ? <a className="modal_product_video" onClick={() => openPrimg(pro?.coverImagePath)} data-bs-toggle="modal" href="#exampleModalToggle" role="button">  <img src={pro?.coverImagePath} alt="img" className="img-fluid" id="pr-img" /> </a> :
                                                                                <a className="modal_product_video" onClick={() => openPrimg(pro?.coverImagePath)} href="javascript:void(0)" role="button"><img src="images/Image_not_available.png" alt="img" className="wc-video img-fluid" id="pr-img" /></a>
                                                                        }


                                                                    </div>
                                                                </div>

                                                                {index == 0 && qouteData?.additionalPdfPath.length > 0 && <div className='pdf_document_main d-none'>
                                                                    <h3> Additional PDF Documents </h3>
                                                                    <div className='pdf_schroll_top'>
                                                                        <ul className="pdf_schroll_slider">

                                                                            {
                                                                                qouteData?.additionalPdfPath?.map((adpdf, index) => {
                                                                                    return (
                                                                                        <li key={index}><a target="_blank" href={adpdf.path}>
                                                                                            <img src={require('../../assets/images/PDF_32_vs.png')} alt="product_banner_SF55" className="img-fluid" />
                                                                                            <b> {adpdf.fileName} </b> </a></li>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </ul>

                                                                    </div>

                                                                </div>}


                                                                {pro?.selectedHandleOption.length > 0 && <div className='handle_option_product'>
                                                                    <h3> Select your handle options </h3>

                                                                    <div className='inner_handle_product'>

                                                                        {
                                                                            pro?.selectedHandleOption?.map((productHandel, index) => {
                                                                                return (
                                                                                    <div key={index} className='listing_product_handle'>
                                                                                        <div className='handle_banner_vs'>
                                                                                            <a href="javascript:void(0)" onClick={() => openHimg(productHandel?.imagePath)}><img src={productHandel?.imagePath} alt="handle" className="img-fluid" /> </a>
                                                                                            {/* <div className="colapsible-icon">  <p> + £{productHandel?.price}</p> </div> */}
                                                                                        </div>
                                                                                        <div className='handle_price_vs disable_qtya'>
                                                                                            {!cpactive &&
                                                                                                <a href='javascript:void(0)' onClick={() => saveHandel(productHandel?.id, productHandel?.price, productHandel?.qty, productHandel?.productId)} className={`select_handle_btn ${(addedHandel.includes(productHandel?.productId + '-' + productHandel?.id) ? 'active' : '')}`}> {(addedHandel.includes(productHandel?.productId + '-' + productHandel?.id) ? 'Selected' : 'Select')} </a>

                                                                                            }

                                                                                            {cpactive &&
                                                                                                <a href='javascript:void(0)' className={`select_handle_btn diabled_seclet_btn ${(addedHandel.includes(productHandel?.productId + '-' + productHandel?.id) ? 'actidve' : '')}`}> {(addedHandel.includes(productHandel?.productId + '-' + productHandel?.id) ? 'Selected' : 'Select')} </a>

                                                                                            }

                                                                                            {(!addedHandel.includes(productHandel?.productId + '-' + productHandel?.id)) &&
                                                                                                <input disabled="act" type="number" min={1} className={`form-control qty_product ${(cpactive || pro?.status == 'V' ? 'disable_hanld_qty' : 'disable_hanld_qty')}`} defaultValue={(productHandel?.productId + '-' + productHandel?.qty == 0 ? 1 : productHandel?.qty)} id={productHandel?.productId + '-' + productHandel?.id + '_hndl'} />

                                                                                            }

                                                                                            {(addedHandel.includes(productHandel?.productId + '-' + productHandel?.id)) &&
                                                                                                <input disabled={`${(cpactive || pro?.status == 'V' ? 'act' : '')}`} onChange={() => changehandelQty(productHandel)} type="number" min={1} className={`form-control qty_product ${(cpactive ? 'disable_hanld_qty' : '')}`} defaultValue={(productHandel?.qty == 0 ? 1 : productHandel?.qty)} id={productHandel?.productId + '-' + productHandel?.id + '_hndl'} />

                                                                                            }



                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>

                                                                </div>}

                                                            </div>
                                                        </div>
                                                    </div>)
                                            }
                                            )}
                                        <p className='top_price_schroll bottom_total_project d-none'><span>Total : </span> £{numberWithCommas((qouteData?.totalAmt + parseFloat(enhAddedamt) + handelAddedamt).toFixed(2))} + VAT </p>
                                    </div>}
                                    <div className="shutter-sec" onClick={() => toggleSec(this)}>
                                        <p>Expand to view your glass</p>
                                        <div className="colapsible-icon"> <img src={require('../../assets/images/arrow-right-vs.png')} alt="gallery_vs" /> </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12 col-lg-2 extra-btn-sec main_button_reviw">
                                <div className='videos_right_buttons'>
                                    <a onClick={() => showPopData(qouteData?.products)} className="btn img-popup-btn" data-bs-toggle="modal" href="#modal-case-study" role="button">  <img src={require('../../assets/images/gallery_vs.png')} alt="gallery_vs" className="img-fluid" />  Products gallery </a>
                                    <a onClick={() => showPopData(qouteData?.products)} className="btn play-popup-btn" data-bs-toggle="modal" href="#video-slider-modal" role="button"> <img src={require('../../assets/images/play_vs.png')} alt="play_vs" className="img-fluid" /> Case Study Videos </a>
                                    <a onClick={() => showPopData(qouteData?.products)} className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle3" role="button"> <img src={require('../../assets/images/info_vs.png')} alt="info_vs" className="img-fluid" /> Additional Resources </a>
                                    <a data-bs-toggle="modal" href="#exampleModalToggle5" role="button" className="btn pdf_doc_pop"> <img src={require('../../assets/images/pdfac.png')} alt="gallery_vs" className="img-fluid" /> Additional PDF Docs </a>
                                </div>



                                <div className='Review_bottom_btn hide-btn'>
                                    <a href="javascript:void(0)" onClick={() => toggleSec(this)} className="review_project"> Review </a>
                                </div>


                            </div>
                        </div>
                    </div>

                    {isLoader && <div className="loader-img-outer"><img src={LoaderImg} className="loader_img_cont" /> </div>}
                </div>

                {/*  <Footer/> */}

            </div>
        </div>
        <div className={`modal project-gallery-modal onep  ${imgprdGallery == '' ? 'd-none' : ''}`} id="prd_gallery_image" tabIndex="-1" aria-labelledby="deleteReqModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">


                    <button type="button" onClick={() => closeprdImg()} className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                    <div className="modal-body btn p-0">
                        <img src={imgprdGallery} alt="img" className="pp-video img-fluid" />

                    </div>
                </div>
            </div>
        </div>
        <div className={`modal project-gallery-modal onep  ${handelImage == '' ? 'd-none' : ''}`} id="prd_gallery_image" tabIndex="-1" aria-labelledby="deleteReqModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content inline_vs_content">


                    <button type="button" onClick={() => closeprdImg()} className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                    <div className="modal-body p-0" >
                        <img src={handelImage} alt="img" className="pp-video img-fluid" />

                    </div>
                </div>
            </div>
        </div>
        <div className="modal video-play-modal onep" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content white-blur-bg">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                    <div className="modal-body btn p-0" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">
                        <img src={prdiamge} alt="img" className="pp-video img-fluid" />
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade second-popup twop" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                    <div className="modal-body">
                        <img src={require('../../assets/images/pp-vdo.png')} alt="img" className="pp-video img-fluid" />
                    </div>
                </div>
            </div>
        </div>


        <div className="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className='row'>
                        <div className="col-9">
                            <div className='video_heading_modal'>
                                <h3> Additional PDF Documents </h3>
                            </div>
                        </div>
                        <div className='col-3'>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                        </div>
                    </div>

                    <div className="modal-body">
                        <div className='pdf_document_main abg'>
                            <div className='pdf_schroll_top'>
                                <ul className="pdf_schroll_slider">

                                    {
                                        qouteData?.additionalPdfPath?.map((adpdf, index) => {
                                            return (
                                                <li key={index}><a target="_blank" href={adpdf.path}>
                                                    <img src={require('../../assets/images/PDF_32_vs.png')} alt="product_banner_SF55" className="img-fluid" />
                                                    <b> {adpdf.fileName} </b> </a></li>
                                            )
                                        })
                                    }

                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className='row'>
                        <div className="col-9">
                            <div className='video_heading_modal'>
                                <h3> Additional Product Resources </h3>
                            </div>
                        </div>
                        <div className='col-3'>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                        </div>
                    </div>

                    <div className="modal-body">
                        {popData && <div className="row">
                            {
                                popData.map((pro, index) => {
                                    return (

                                        <div key={index} className="col-12">
                                            <div className='pdf_document_main modal_project_pdf'>
                                                <h3> {pro?.productName}  </h3>
                                                {pro?.media?.info.length > 0 && <div className='pdf_schroll_top'>

                                                    <ul className="pdf_schroll_slider modal_pdf_products">

                                                        {
                                                            pro?.media?.info.map((info, index) => {
                                                                return (
                                                                    <li key={index}><a href={info.path} target="_blank" ><img className="img-fluid" alt="{info.fileName}" src={PagesSvg.pdfIconSvg} /> <b> {info.fileName} </b></a></li>

                                                                )
                                                            })

                                                        }
                                                    </ul>

                                                </div>}
                                                {pro?.media?.info.length == 0 && <div className='pdf_schroll_top'><span className="no_video_lable">No additional product resources found</span></div>}

                                            </div>
                                        </div>

                                    )
                                })

                            }

                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="modal blur-overlay videos-slider-popup-overlay" id="modal-case-study" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    {popData && <div className='gallery'>
                        {
                            popData.map((pro, index) => {
                                return (
                                    <div key={index} className='row'>
                                        <div className="col-9">
                                            <div className='video_heading_modal'>
                                                <h3> {pro?.productName} </h3>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row align-items-center">
                                                <div className='col-12'>
                                                    {pro?.selectedPrintPdfPath.length > 0 && <div className='main_scroll_product'>

                                                        {
                                                            pro?.selectedPrintPdfPath.map((imager, index) => {
                                                                return (
                                                                    <div key={index} className='outer_videos_modal'>
                                                                        <a href="javascript:void(0)" onClick={() => openGPrimg(imager)}  ><img src={imager} alt="gallery_vs" />   </a>
                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                    </div>}
                                                    {pro?.selectedPrintPdfPath.length == 0 && <div className='main_scroll_product'> <span className="no_video_lable">No gallery images found</span></div>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })

                        }

                    </div>}
                </div>
            </div>
        </div>
        <div className="modal blur-overlay videos-slider-popup-overlay" id="video-slider-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">

                <div className="modal-content">
                    {popData && <div className='gallery'>
                        {
                            popData.map((pro, index) => {
                                return (
                                    <div key={index} className='row'>
                                        <div className="col-9">
                                            <div className='video_heading_modal'>
                                                <h3> {pro?.productName} </h3>
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row align-items-center">
                                                <div className='col-12'>
                                                    {pro?.media?.videos.length > 0 && <div className='main_scroll_product'>

                                                        {
                                                            pro?.media?.videos.map((videor, index) => {
                                                                return (
                                                                    <div key={index} className='outer_videos_modal'>
                                                                        <video width="250" height="210" controls>
                                                                            <source src={videor.path} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.</video>
                                                                    </div>
                                                                )
                                                            })

                                                        }

                                                    </div>}

                                                    {pro?.media?.videos.length == 0 && <div className='main_scroll_product'> <span className="no_video_lable">No videos found</span> </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })

                        }

                    </div>}
                </div>
            </div>
        </div>
        <div className="modal blur-overlay videos-slider-popup-overlay" id="modal-terms-condition" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className='gallery'>
                        <div className='row'>
                            <div className="col-9">
                                <div className='video_heading_modal'>
                                    <h3> Terms And Conditions </h3>
                                </div>
                            </div>
                            <div className='col-3'>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row align-items-center">
                                    <div className='col-12'>
                                    <div dangerouslySetInnerHTML={{ __html: TermsConditionData }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </motion.div>);

}
export default Project;