import PrintIcon from '../../assets/images/zocial-print.svg';
import InfoIcon from '../../assets/images/icon_material-info-black.svg';
import TrashIcon from '../../assets/images/material-delete-forever.svg';
import CardIcon from '../../assets/images/metro-credit-card.svg';
import CardtransferIcon from '../../assets/images/payment-bank-transfer.svg';
import ApplePayIcon from '../../assets/images/simple-applepay.svg';
import GooglePayIcon from '../../assets/images/simple-googlepay.svg';
import AwardIcon from '../../assets/images/feather-award.svg';
import CaArrowRightIcon from '../../assets/images/ionic-md-arrow-dropdown-circle.svg';
import CaArrowLeftIcon from '../../assets/images/Icon ionic-md-arrow-dropleft-circle.svg';
import PhoneIcon from '../../assets/images/zocial-call.svg';
import MailIcon from '../../assets/images/material-mail.svg';
import LinkedIcon from '../../assets/images/ionic-logo-linkedin.svg';
import StartIcon from '../../assets/images/6699160.svg';
import CheckIcon from '../../assets/images/pro-tick.svg';
import VanIcon from '../../assets/images/delivery_van.svg';
import pdfIcon from '../../assets/images/pdficon.svg';

export default{
	PrintSvg: PrintIcon,
    InfoSvg: InfoIcon,
    TrashSvg: TrashIcon,
    CardSvg: CardIcon,
    CardtransferIconSvg: CardtransferIcon,
    ApplePayIconSvg: ApplePayIcon,
    GooglePayIconSvg: GooglePayIcon,
    AwardIconSvg: AwardIcon,
    CaArrowRightIconSvg: CaArrowRightIcon,
    CaArrowLeftIconSvg: CaArrowLeftIcon,
    PhoneIconSvg: PhoneIcon,
    MailIconSvg: MailIcon,
    LinkedIconSvg: LinkedIcon,
    StartIconSvg: StartIcon,
    CheckIconSvg: CheckIcon,
    VanIconSvg: VanIcon,
    pdfIconSvg: pdfIcon,
}

