import React, { useEffect, useState } from 'react';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import 'aos/dist/aos.css';
import moment from 'moment/moment';
import { ApiUrl, AdminApiUrl,GetQuoteId } from '../../utils/utils';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Lottie from 'react-lottie';
import axios from 'axios';
import { Link } from "react-router-dom";
import LoaderImg from "../../assets/images/loader.gif";
import * as animationData from '../../assets/js/7280801.json'
 

function MakeRequest () {

  const QuoteId = GetQuoteId();
  const [isLoader, setisLoader] = useState(false);
  const [qouteData, setQouteData] = useState('');
  const[topic, setTopic] = useState('')
  const[question, setQuestion] = useState('')
  const[topicErr, setTopicErr] = useState(false)
  const[questionErr, setQuestionErr] = useState(false)
  const[requestMessage, setRequestMessage] = useState('')

  const sendRequest = () => {
        setTopicErr(false);
        setQuestionErr(false);
        setRequestMessage('');

        if(topic == ''){
            setTopicErr(true);
            return;
        }
        if(question == ''){
            setQuestionErr(true);
            return;
        }
        const data = {};
        data.QuoteId = QuoteId;
       
        data.Topic = topic;
        data.Question = question;
        data.ProductId = 0;
       
       const option = {
        method: 'POST', headers: {
            "access-control-allow-origin": "*",
            "content-type": "application/json",
            "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
        },
        data: data,
        url: `${ApiUrl()}/v1/Quote/MakeRequest`
        };
        axios(option)
        .then((e) => {
            
            if(e?.data?.statusCode == 200){
                setRequestMessage('Your request has been seccessfully submitted!');
                setTopic('');
                setQuestion('');
            }else{
                setRequestMessage('Something went wrong. Please contact to admin support!');
            }
            
        })
        .catch((err) => {
        })
    }
    const GetQuote = () => {
        setisLoader(true);
        const data = {};
        data.QuoteId = QuoteId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`
        };
        axios(option)
            .then((e) => {
                setisLoader(false);
                setQouteData(e?.data?.data);
            })
            .catch((err) => {
            })
    }

  useEffect(() => {
    GetQuote();
      AOS.init({ duration: 1000 });
  }, []);

  const printPdf =(pdfFile) => {
   window.open(pdfFile, "PRINT", "height=400,width=600");
  }  


return (<div className="container-fluid">
  <div className="row">
    <div className="col-md-3 col-lg-2 px-0">
      <Sidebar />
    </div>
    <div className="col-md-9 col-lg-10  review-page position-relative">
      <div className="main-content align-items-start py-5 flex-column">
        <div className="bg-image" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
          <img src={qouteData?.coverImagePath} alt="img" className="bg-img img-fluid" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
          
                      <div className='outer_make_form'>
                      <div className="contact-form-sec ">
                                <h3>Make A Request</h3>
                                
                                <input type="text" name="topic_section" className="form-control" onChange={(topic) =>
                  setTopic(topic.target.value)
                } value={topic} placeholder="Topic..." required/>
                {topicErr && <span className="err">Please write about your topic!</span> }


                                 <textarea id="w3review" name="w3review" rows="7" cols="50" onChange={(question) =>
                  setQuestion(question.target.value)
                } placeholder="Question..." value={question} required></textarea>
                {questionErr && <span className="err">Please write about your question!</span> }
                                <a href="javascript:void(0)" className="btn btn-primary send-btn" role="button" onClick={() => sendRequest()}>Send</a>

                                {requestMessage != '' && <span className="succes_msg">{requestMessage}</span>}
                            </div>
                      </div>

            </div>
          </div>
        </div>
      </div> {isLoader && <div className="loader-img-outer">
        <img src={LoaderImg} className="loader_img_cont" />
      </div> }
    </div>
  </div>
</div>);
    
}
export default MakeRequest;