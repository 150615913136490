import React, { useEffect, useState } from "react";
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
import LoaderImg from "../../assets/images/loader.gif";
import Pdffile from "../../assets/Q-00006_CONTRACT.pdf";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import SignatureCanvas from "react-signature-canvas";
import { ApiUrl, AdminApiUrl, GetQuoteId } from "../../utils/utils";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
function Terms() {
  const QuoteId = GetQuoteId();
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [signPricture, setSignPricture] = useState(null);
  const [signPricturebase, setSignPrictureBase] = useState(null);
  const [ValidimageErr, setValidimageErr] = useState(false);
  const [isLoader, setisLoader] = useState(true);
  const [isTerm, SetisTerm] = useState(false);
  const [signText, SetsignText] = useState(null);
  const [qouteData, setQouteData] = useState("");
  const [pdfPath, setPdfpath] = useState("");
  const [agreementVal, setAgreementVal] = useState(false);
  const [isshowPdfview, setIsshowPdfview] = useState(false);
  const [IsViewpdf, setIsViewpdf] = useState(false);

  let sigPad = {};
  let navigate = useNavigate();
  const clear = () => {
    setTrimmedDataURL(null);
    sigPad.clear();
  };
  const trim = () => {
    setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));
  };
  const uploadSigntueImage = (image) => {
    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setValidimageErr(true);
      document.getElementById("sign_img").value = "";
      return false;
    } else if (image.size >= 100000) {
      setValidimageErr(true);
      document.getElementById("sign_img").value = "";
      return false;
    } else {
      setValidimageErr(false);
      let fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      fileReader.onload = (event) => {
        setSignPrictureBase(event.target.result);
      };
      setSignPricture(image);
    }
  };
  const removeProfilepic = () => {
    setSignPricture(null);
    setSignPrictureBase(null);
    document.getElementById("sign_img").value = "";
  };

  const handleChange = (event) => {
    setAgreementVal(event.target.checked);
  };

  const resetImg = () => {
    setSignPrictureBase(null);
    setSignPricture(null);
    setTrimmedDataURL(null);
    SetsignText(null);
    sigPad.clear();
  };
  const openPdf = (pdfPath, isFor) => {
    setPdfpath(pdfPath);
    setIsshowPdfview(true);

    if (isFor == 1) {
      localStorage.setItem("isviewTerm", "1");
      setIsViewpdf(true);
    }
  };
  const typeSign = (textVal) => {
    let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
    canvasTxt.canvas.width = canvasTxt.measureText(textVal).width + 20;
    canvasTxt.canvas.height = 25;
    canvasTxt.font = "20px Arial bold";
    canvasTxt.color = "#000";
    canvasTxt.fillText(textVal, 10, 20);
    SetsignText(canvasTxt.canvas.toDataURL());
  };
  const GetQuote = () => {
    const data = {};
    data.QuoteId = QuoteId;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`,
    };
    axios(option)
      .then((e) => {
        SetisTerm(true);
        if (e?.data?.data?.quoteContractInfoModel?.isSent == true) {
          SetisTerm(true);
        }
        if (e?.data?.data?.contractFilePath != "") {
          setPdfpath(e?.data?.data?.contractFilePath);
        }

        setisLoader(false);
        setQouteData(e?.data?.data);
        console.log(e?.data?.data);
      })
      .catch((err) => { });
  };
  const accpetTerm = () => {
    let signImgD = sigPad.toData();
    let imgBase64 = "";
    if (!agreementVal) {
      alert("Please read and accept temrs & conditions first!");
      return;
    } else if (
      signPricturebase == null &&
      signImgD.length == 0 &&
      signText == null
    ) {
      alert("Please draw or upload signature first!");
      return;
    } else {
      if (signPricturebase != null) {
        imgBase64 = signPricturebase;
      }
      if (signImgD.length > 0) {
        let signImg = sigPad.getTrimmedCanvas().toDataURL("image/png");
        imgBase64 = signImg;
      }
      if (signText != null) {
        imgBase64 = signText;
      }
    }
    setisLoader(true);
    const data = {};
    data.QuoteId = QuoteId;
    data.Base64String = imgBase64;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${AdminApiUrl()}/sign/UploadSignature`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        return navigate("/payments");
      })
      .catch((err) => { });
  };
  const df = () => {
    document
      .querySelector("iframe")
      .contentWindow.document.querySelector("toolbarContainer").style.color =
      "red";
    $(".iframde").contents().find(".toolbar").addClass("Helo");
  };
  useEffect(() => {
    GetQuote();
    AOS.init({ duration: 2000 });

    let view_status = localStorage.getItem("isviewTerm");
    if (view_status == "1") {
      setIsViewpdf(true);
    }
  }, []);
  const printPdf = (pdfFile) => {
    window.open(pdfFile, "PRINT", "height=400,width=600");
  };
  //   const NextArrow: React.FC<ArrowsProps> = ({ onClick, className }) => (
  //     <div onClick={onClick} className="slick-prev">
  //       <img
  //         src={PagesSvg.CaArrowLeftIconSvg}
  //         alt="img"
  //         className="img-fluid"
  //         id="prv-img"
  //       />
  //     </div>
  //   );

  const NextArrow = ({ onClick, className }) => (
    <div onClick={onClick} className="slick-prev">
      <img
        src={PagesSvg.CaArrowLeftIconSvg}
        alt="img"
        className="img-fluid"
        id="prv-img"
      />
    </div>
  );
  const PrevArrow = ({ onClick, className }) => (
    <div onClick={onClick} className="slick-next">
      <img
        src={PagesSvg.CaArrowLeftIconSvg}
        alt="img"
        className="img-fluid"
        id="nxt-img"
      />
    </div>
  );
  var settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    vertical: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <NextArrow />,
    nextArrow: <PrevArrow />,
  };
  var urlEncoded = encodeURI(
    "https://ysadmin.showmysite.net/Upload/AlexSullivan@armyspy.com/Info/file-example_PDF_1MB.pdf"
  );
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-lg-2 px-0">
          <Sidebar />
        </div>
        <div className="col-md-9 col-lg-10 terms-page position-relative">
          <div className="main-content align-items-start py-5">
            <div className="bg-image">
              {" "}
              <img
                src={qouteData?.coverImagePath}
                alt="img"
                className="bg-img img-fluid"
              />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="term_head_section">
                    {qouteData?.quoteContractInfoModel?.isSignAccepted == false && (
                      <h2>Please sign your sales contract</h2>
                    )}
                    {qouteData?.quoteContractInfoModel?.isSignAccepted == true && (
                      <h4>Contract has been signed</h4>
                    )
                    }
                    <span> {qouteData?.ygNumber} </span>
                  </div>
                  {qouteData?.quoteContractInfoModel?.isSignAccepted == false && (
                  <div className="term_sub_head_section">
                    <p>
                      {qouteData.customerName}, your sales contract is ready.
                      Please review and confirm your sales contract. You will
                      need to confirm our terms & conditions and lead time
                      confirmed documents to proceed to payments. Any questions,
                      please contact {qouteData.salesPersonEmail}.
                    </p>
                  </div>)}

                  {qouteData?.quoteContractInfoModel?.isSignAccepted == true && (
                  <div className="term_sub_head_section">
                    <p>
                      {qouteData.customerName}, your sales contract is ready. Any questions, please contact {qouteData.salesPersonEmail}.
                    </p>
                  </div>)}
                </div>
              </div>
              <div className="row">
                {isTerm && isshowPdfview && (
                  <div className="col-lg-6 col-md-7">
                    <div className="terms-page-content">
                      <div className="tpc-scroll-area">
                        {qouteData?.quoteContractInfoModel?.isSignAccepted ==
                          false &&
                          qouteData?.quoteContractInfoModel?.isSent == true && (
                            <iframe
                              controls="0"
                              src={pdfPath}
                              className="iframde"
                              title="title"
                              width="500"
                              height="180"
                            >
                              Presss me: <a href={pdfPath}>Download PDF</a>
                            </iframe>
                          )}
                        {qouteData?.quoteContractInfoModel?.isSignAccepted ==
                          true &&
                          qouteData?.quoteContractInfoModel?.isSent == true && (
                            <iframe
                              className="iframde"
                              controls="0"
                              src={pdfPath}
                              title="title"
                              width="500"
                              height="400"
                              max-width="100%"
                            >
                              Presss me: <a href={pdfPath}>Download PDF</a>
                            </iframe>
                          )}
                      </div>
                    </div>
                  </div>
                )}
                {!isTerm && (
                  <div className="col-lg-9 col-md-8">
                    <div className="terms-page-content">
                      <h3>
                        Thank you for approving your Quotation. <br />{" "}
                        {qouteData?.salesPersonName} will be in touch shortly to
                        discuss your contract.
                      </h3>
                    </div>
                  </div>
                )}
                <div
                  className={`${isshowPdfview ? "col-lg-6 col-md-5" : "col-lg-12 col-md-10"
                    }`}
                >
                  <div className="customer-review-sec">
                    {isLoader && (
                      <div className="loader-img-outer">
                        <img src={LoaderImg} className="loader_img_cont" />{" "}
                      </div>
                    )}
                    <div className="signature-sec">
                      <div className="pdf_doc_section">
                        {qouteData?.quoteContractInfoModel?.isSent == true && (
                          <div className="innr_section_pdf pdf_setion_view">
                            <div className="in_section">
                              <a href="javascript:void(0)">
                                <img
                                  width="100"
                                  height="100"
                                  className="img-fluid"
                                  alt="{info.fileName}"
                                  src={PagesSvg.pdfIconSvg}
                                />{" "}
                              </a>
                              <b>
                                {" "}
                                {qouteData?.contractFilePath.replace(
                                  /^.*[\\\/]/,
                                  ""
                                )}{" "}
                              </b>{" "}
                              <a
                                onClick={() =>
                                  openPdf(qouteData?.contractFilePath, 1)
                                }
                                href="javascript:void(0)"
                              >
                                View
                              </a>
                            </div>
                            <div className="in_section">
                              <a href="javascript:void(0)">
                                <img
                                  width="100"
                                  height="100"
                                  className="img-fluid"
                                  alt="{info.fileName}"
                                  src={PagesSvg.pdfIconSvg}
                                />{" "}
                              </a>
                              <b>
                                {" "}
                                {qouteData?.leadTimeDocumentPath.replace(
                                  /^.*[\\\/]/,
                                  ""
                                )}{" "}
                              </b>{" "}
                              <a
                                onClick={() =>
                                  openPdf(qouteData?.leadTimeDocumentPath, 2)
                                }
                                href="javascript:void(0)"
                              >
                                View
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {qouteData?.quoteContractInfoModel?.isSignAccepted ==
                      false && (
                        <div className="signature-sec">
                          {IsViewpdf && (
                            <div className="term_condition_sec">
                              <input
                                type="checkbox"
                                name="agreement"
                                onChange={handleChange}
                              />
                              <p>
                                Tick the box to confirm that you agree to both the
                                terms and conditions and lead time explained pdf
                                documents.
                              </p>
                            </div>
                          )}
                          <nav>
                            <div
                              className="nav nav-tabs"
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                className="nav-link active"
                                onClick={() => resetImg()}
                                id="nav-draw-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-draw"
                                type="button"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected="true"
                              >
                                Draw
                              </button>
                              <button
                                className="nav-link"
                                id="nav-type-tab"
                                onClick={() => resetImg()}
                                data-bs-toggle="tab"
                                data-bs-target="#nav-type"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                              >
                                Type
                              </button>
                              <button
                                className="nav-link"
                                id="nav-upload-tab"
                                onClick={() => resetImg()}
                                data-bs-toggle="tab"
                                data-bs-target="#nav-upload"
                                type="button"
                                role="tab"
                                aria-controls="nav-upload"
                                aria-selected="false"
                              >
                                Upload
                              </button>
                            </div>
                          </nav>
                          <div className="tab-content" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="nav-draw"
                              role="tabpanel"
                              aria-labelledby="nav-draw-tab"
                            >
                              <div className="signature-draw mt-50">
                                <SignatureCanvas
                                  canvasProps={{ className: "sigPad" }}
                                  ref={(ref) => {
                                    sigPad = ref;
                                  }}
                                />
                                <div className="clear_pad_btn">
                                  <button className="btn " onClick={clear}>
                                    Clear
                                  </button>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => accpetTerm()}
                                    className="btn accept-sign-btn"
                                    role="button"
                                  >
                                    Accept & Sign
                                  </a>
                                </div>
                                {/*<button className="btn " onClick={trim}>
                                    Ok
                                    </button>*/}
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-type"
                              role="tabpanel"
                              aria-labelledby="nav-type-tab"
                            >
                              <div className="type_sig_outer">
                                <input
                                  type="text"
                                  className="type_signature"
                                  onChange={(txtVal) =>
                                    typeSign(txtVal.target.value)
                                  }
                                  placeholder="Type your signature..."
                                />
                              </div>
                              <canvas
                                id="canvasComponent"
                                style={{ display: "none" }}
                              />
                              <div className="clear_pad_btn">
                                {" "}
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => accpetTerm()}
                                  className="btn accept-sign-btn"
                                  role="button"
                                >
                                  Accept & Sign
                                </a>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-upload"
                              role="tabpanel"
                              aria-labelledby="nav-upload-tab"
                            >
                              <div className="img_upd_secction">
                                <input
                                  type="file"
                                  onChange={(event) => {
                                    uploadSigntueImage(event.target.files[0]);
                                  }}
                                  className="form-control"
                                  placeholder="Upload Profile Photo"
                                  id="sign_img"
                                  aria-describedby="inputGroupFileAddon01"
                                ></input>
                                <div className="img_show_image">
                                  {ValidimageErr && (
                                    <span className="err">
                                      Please select valid image! (Format -
                                      jpg|jpeg|png|gif and Less than 100 kb size)
                                    </span>
                                  )}
                                  <span className="sderr">
                                    Please use a picture less than 100 kb
                                  </span>
                                  {signPricture && (
                                    <div className="fc-img">
                                      <img
                                        alt="not found"
                                        width={"50px"}
                                        src={URL.createObjectURL(signPricture)}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="clear_pad_btn">
                                {" "}
                                <button
                                  className="btn submit-btn"
                                  type="button"
                                  onClick={() => removeProfilepic()}
                                >
                                  Remove
                                </button>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => accpetTerm()}
                                  className="btn accept-sign-btn"
                                  role="button"
                                >
                                  Accept & Sign
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal blur-overlay fade print-popup-overlay"
        id="exampleModalToggle1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="quote-image-sec">
                    <img
                      src={qouteData?.coverImagePath}
                      alt="img"
                      className="logoforpopup img-fluid"
                    />
                    <div className="qis-content">
                      <h5>Contract of works Terms & Conditions</h5>
                    </div>
                  </div>
                </div>
                {qouteData?.contractFilePath != "" && (
                  <div className="col-sm-6">
                    <h1>Print a copy</h1>
                    <p>Click print or download to receive your pdf copy</p>
                    <a
                      target="_blank"
                      href={qouteData?.contractFilePath}
                      className="btn btn-primary download-btn"
                      download
                    >
                      Download
                    </a>
                    {/*{qouteData?.contractFilePath && 
                     <Link to={qouteData?.contractFilePath} target="_blank" download className="btn btn-primary download-btn" rel="noopener noreferrer" />
                     } */}
                    <a
                      href="javascript:void(0)"
                      onClick={() => printPdf(qouteData?.contractFilePath)}
                      className="btn btn-primary print-btn"
                      role="button"
                    >
                      Print
                    </a>
                  </div>
                )}
                {qouteData?.contractFilePath == "" && (
                  <div className="col-sm-6">
                    <h1>No document is ready for download yet!</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal blur-overlay fade info-popup-overlay"
        id="exampleModalToggle2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h5>
                    Stonly.com <br />
                    Support info
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Terms;
