import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/js/6699160.json'
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ApiUrl, GetUserId, GetQuoteId } from '../../utils/utils';
import axios from 'axios';
import LoaderImg from "../../assets/images/loader.gif";
import CommonSvg from "../Constant/CommonSvg";


var myTimer;
var myTimerD = '1';
const Team = () => {

    const userId = GetUserId();
    const QuoteId = GetQuoteId();
    const [animateClass, setanimateClass] = useState('');
    const [userInfoData, setUserInfoData] = useState([]);
    const [isLoader, setisLoader] = useState(false);
    const [qouteData, setQouteData] = useState('');
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const userInfo = () => {
        setisLoader(true);
        const data = {};
        // data.Id = 6
        data.UserId = userId
        data.QuoteId = QuoteId
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Account/GetUserInfo`,
        };
        axios(option)
            .then((e) => {
                setisLoader(false);
                setUserInfoData(e?.data?.data);
                let dt = e?.data?.data;
            })
            .catch((err) => {
            })
    }

    const GetQuote = () => {
        setisLoader(true);
        const data = {};
        data.QuoteId = QuoteId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`
        };
        axios(option)
            .then((e) => {
                setisLoader(false);
                setQouteData(e?.data?.data);
            })
            .catch((err) => {
            })
    }

    useEffect(() => {
        userInfo()
        GetQuote()
        AOS.init({ duration: 2000 });
    }, [userId, QuoteId])
    return (
        <div className="container-fluid team-page outer_team_pagesvs">
            <div className="row">
                <div className="col-md-3 col-lg-2 px-0">
                    <Sidebar />
                </div>
                <div className="col-md-9 col-lg-10 position-relative">
                    <div className="main-content">
                        <div className="bg-image" data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"> <img src={qouteData?.coverImagePath} alt="img" className="bg-img img-fluid" /></div>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className='header_team_vs text-center'>
                                        <h1 className="main-heading">Your Team </h1>
                                        {/* <p> Your complete team will appear once your contract has been signed </p> */}
                                    </div>
                                </div>
                            </div>
                            <div className={"row position-relative" + (animateClass != '' ? animateClass : 'width-without-animation')}>
                                {
                                    userInfoData?.map((pro, index) => {
                                        return (<div key={index} className="col-lg-4 col-12 col-sm-6 col-md-6">
                                            <div className="team-member">
                                                <div className="member-profile-pic member-profile-cstm">

                                                    {pro?.profilePicPath ? <img src={pro?.profilePicPath} alt="img" className="profile-pic img-fluid" /> :
                                                        <img src="images/Image_not_available.png" alt="img" className="profile-pic img-fluid" />
                                                    }
                                                </div>
                                                <div className='bg_black_transparent_team'>
                                                    <h3>{pro?.name}</h3>
                                                    <h4>({pro?.roles})</h4> 
                                                     {pro?.roles!='Installer' &&  <ul className="team-member-contacts">
                                                        {pro?.email ? <li><a href={`mailto:${pro?.email + '?subject=Question for YesGlazing'}`}>e:{pro?.email} </a></li> : null
                                                        }
                                                        {/* {
                                                            pro?.mobileNo ?
                                                                <li><a href={`tel:${pro?.mobileNo}`}>
                                                                    m: {pro?.mobileNo} </a></li>
                                                                : null
                                                        } */}
{
                                                            pro?.landLineNo ?
                                                                <li><a href={`tel:${pro?.landLineNo}`}>
                                                                    l: {pro?.landLineNo} </a></li>
                                                                : null
                                                        }
                                                    </ul>}
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div>
                            {isLoader && <div className="loader-img-outer"><img src={LoaderImg} className="loader_img_cont" /> </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team;