import React, { useEffect, useState } from 'react';
import { withRouter } from "../Common/withRouter";
import CommonSvg from "../Constant/CommonSvg";
import * as animationData from '../../assets/js/6699160.json'
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PropTypes from "prop-types";
import { ApiUrl, GetQuoteId, SaveQuoteId, SaveUserId } from '../../utils/utils';
import { browserName } from 'react-device-detect';
var myTimer;
class Start extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context);
    this.state = { animate: false };
    this.state = { animateClass: '', isTeam: false };
    this.state = { data: null }
    this.state = { isPrviewmode: null }
    this.state = { ipAddress: null };
    this.state = { salesPerson: null };
    AOS.init({ duration: 2000 });
    this.quoteInfo(`${ApiUrl()}/v1/Quote/GetQuoteDetail`)
  }

  yssRemove = (navigate) => {
    const { isPrviewmode } = this.state;
    $(".yss-first-quote").css('opacity', '0');
    $(".yss-lottie-ani").css('opacity', '1');
    $(".yss-brand").css('opacity', '1');

    myTimer = setTimeout(this.myGreeting, 0);
    setTimeout(this.secSecction, 0)
    setTimeout(this.removeSecction, 0)
    if (isPrviewmode == null) {
      this.quoteDetail(`${ApiUrl()}/v1/Quote/GetQuoteClientDetail`)
    }
  }

  myGreeting() {
    $(".yss-lottie-ani").css({ 'bottom': '0%', 'opacity': '0' });
    clearTimeout(myTimer);
  }

  secSecction() {
    $(".yss-welcome-message").css({ 'top': '50%', 'opacity': '1' });
  }

  removeSecction = () => {
    $(".yss-main-wrapper").addClass("hide-wrapper");
    this.props.navigate('/project');
  }

  componentDidMount() {
    this.timer = setInterval(
      () => this.setState({ animateClass: 'width-with-animation' }),
      100,
    );
    const params = new Proxy(new URLSearchParams(window?.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    localStorage.setItem('previewMode', 'notpreview');
    if (params?.quoteId != null) {
      let urdl = params?.quoteId.split(' ').join('+');
      SaveUserId(params?.userId.split(' ').join('+'));
      SaveQuoteId(urdl);
    }
    if (params?.mode != null) {
      localStorage.setItem('previewMode', params?.mode);
      this.setState({ isPrviewmode: 1 });
    }
    fetch('https://geolocation-db.com/json/')
      .then(response => response.json())
      .then(data => {
        this.setState({ ipAddress: data.IPv4 });

      })
      .catch(error => { })
  }

  quoteDetail = (quote) => {
    const { ipAddress } = this.state;
    fetch(quote, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'api_key': 'B616VV5-7ED4Q7B-MH2562J-ED281YB',
      },
      body: JSON.stringify({ QuoteId: GetQuoteId(), 'browserName': browserName, 'ipAddress': ipAddress })
    })
      .then(result => result.json())
      .then((data) => {
        console.log(data)
        this.setState({
          data: data?.data,
        })
      })
      .catch((error) => {
      })
  }

   quoteInfo = (quote) => { 
    fetch(quote, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'api_key': 'B616VV5-7ED4Q7B-MH2562J-ED281YB',
      },
      body: JSON.stringify({ QuoteId: GetQuoteId() })
    })
      .then(result => result.json())
      .then((data) => {
        this.setState({ salesPerson: data?.data?.salesPersonName });
      })
      .catch((error) => {
      })
  }

  render() {
    const { salesPerson } = this.state;   
    return <div className="container-fluid team-page">
      <div className="row yss-main-wrapper">
        <div className="col-12">
          <div className="yes-start-screen d-flex justify-content-center align-items-center flex-column">
            <div className="yss-first-quote">
              <div className='outer_start_main'>
                <a href='#'>  <img src={CommonSvg.Weblogo} alt="icon" /> </a>
                <a href="javascript:void(0)" onClick={this.yssRemove.bind(this, 1)} className='button_start_glazing'>View your Quote</a>
                <p> Your Quotation has been created by {salesPerson}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
export default withRouter(Start);

