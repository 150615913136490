import YourTeam from '../../assets/images/your_team_icon.svg';
import Wlogo from '../../assets/images/logo_sidebar_new.svg';
import YourTeamHover from '../../assets/images/menu_green_icons/play-green.svg';
import YourGlass from '../../assets/images/your_glass_icon.svg';
import YourGlassHover from '../../assets/images/menu_green_icons/search-data-green.svg';
import Review from '../../assets/images/review_icon.svg';
import ReviewHover from '../../assets/images/menu_green_icons/quotation-green.svg';
import TermsSign from '../../assets/images/terms_sign_icon.svg';
import TermsSignHover from '../../assets/images/menu_green_icons/contract-green.svg';
import Payments from '../../assets/images/payments_icon.svg';
import PaymentsHover from '../../assets/images/menu_green_icons/credit-card-green.svg';
import Progress from '../../assets/images/progress_icon.svg';
import ProgressHover from '../../assets/images/menu_green_icons/clock-green.svg';
import AboutUs from '../../assets/images/about_us_icon.svg';
import ChatIcon from '../../assets/images/ionic-ios-chatbubbles.svg';
import Weblogo from '../../assets/images/logo_home.svg';
import PhoneSvgImg from '../../assets/images/telephone.svg';
import EmalSvgImg from '../../assets/images/mail.svg';

import Makerequest from '../../assets/images/menu_green_icons/request_form-form.svg';
import MakerequestsHover from '../../assets/images/menu_green_icons/request_form-form-green.svg';


export default{
    Wlogo: Wlogo,
	TeamSvg: YourTeam,
    TeamHoverSvg: YourTeamHover,
	GlassSvg: YourGlass,
    GlassSvgHover: YourGlassHover,
	ReviewSvg: Review,
    ReviewSvgHover: ReviewHover,
    TermsSvg: TermsSign,
    TermsSvgHover: TermsSignHover,
    PaymentsSvg: Payments,
    PaymentsSvgHover: PaymentsHover,
    ProgressSvg: Progress,
    ProgressSvgHover: ProgressHover,
    AboutSvg: AboutUs,
    ChatSvg: ChatIcon,
    Weblogo:Weblogo,
    PhoneSvgImg: PhoneSvgImg,
    EmalSvgImg:EmalSvgImg,
    MakerequestImg:Makerequest,
    MakerequestsHoverImg:MakerequestsHover
}
