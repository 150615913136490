import React from 'react';
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
class About extends React.Component {
render() {
return <div className="container-fluid">
    <div className="row">
        <div className="col-md-3 col-lg-2 px-0">
            <Sidebar />
        </div>
        <div className="col-md-9  col-lg-10 about-us-page position-relative">
            <div className="main-content align-items-start py-5">
                
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section">
                                
                                <h1>About Us</h1>
                                
                            </div>
                        </div>
                    </div>
                    
                    <div className="row my-5">
                        <div className="col-lg-9 col-md-8">
                            <p>YES Window Company Ltd T/as YES Glazing Solutions Great South Street Farm, 428 Main Road, Westerham, Kent, TN2HP</p>
                            <p><span><b>T:</b> 01959 525 629</span> <span className="ps-5"><b>F:</b> 01959 540 620</span> <span className="ps-5"><b>E:</b> info@yesglazing.co.uk</span></p>
                            <p className="font-light">VAT No 784 1832 08 REG No: 6153629</p>
                            <div className="row my-5">
                                <div className="col-lg-5 col-md-12 col-sm-6 pe-lg-5 pe-md-0 pe-sm-5">
                                    <div className="about-video-sec">
                                        <img src={require('../../assets/images/welcome_video_popup.png')} alt="img" className="fds img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12 col-sm-6 ps-lg-5 ps-md-0 ps-sm-5">
                                    <div className="about-detail-sec">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into</p>
                                        <p>electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div className="col-lg-3 col-md-4">
                            <div className="about-process-sec">
                                <h4 className="font-light mb-5 text-center">About us & Our Processes</h4>
                                <div className="aps-video-sec"><img src={require('../../assets/images/aboutprocess01.png')} alt="img" className="fds img-fluid mb-5"/></div>
                                <div className="aps-video-sec"><img src={require('../../assets/images/aboutprocess02.png')} alt="img" className="fds img-fluid mb-5"/></div>
                                <div className="aps-video-sec"><img src={require('../../assets/images/aboutprocess03.png')} alt="img" className="fds img-fluid mb-5"/></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section">
                                <h1 className="mb-5">Our Awards</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="award-wrapper mb-5">
                                <img src={PagesSvg.AwardIconSvg} alt="icon" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="award-wrapper mb-5">
                                <img src={PagesSvg.AwardIconSvg} alt="icon" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="award-wrapper mb-5">
                                <img src={PagesSvg.AwardIconSvg} alt="icon" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="award-wrapper mb-5">
                                <img src={PagesSvg.AwardIconSvg} alt="icon" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section">
                                <h1 className="mb-5">Our service</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section">
                                <h1 className="mb-5">Why people choose Yes Glazing</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section">
                                <h1 className="mb-5">Why we love what we do</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-section">
                                <h1 className="mb-5">What does yielding excellence mean</h1>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div> 
        </div>
    </div>
    
</div>;
}
}
export default About;