import React, { useEffect, useState } from "react";
//import ReactDOM from 'react-dom/client';
import $ from "jquery";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/black_close_modal.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "aos/dist/aos.css";
import moment from "moment/moment";
import { ApiUrl, AdminApiUrl, GetQuoteId } from "../../utils/utils";
import AOS from "aos";
import "aos/dist/aos.css";
import Lottie from "react-lottie";
import axios from "axios";
import { Link } from "react-router-dom";
import LoaderImg from "../../assets/images/loader.gif";
import * as animationData from "../../assets/js/7280801.json";
import "./Review.css";
import { motion } from "framer-motion";

function Review() {
  const QuoteId = GetQuoteId();
  const [qouteData, setQouteData] = useState([]);
  const [SelectedProduct, setselectedProduct] = useState(null);
  const [pactive, setPactive] = useState(false);
  const [cpactive, setCpactive] = useState(false);
  const [ispreview, setIspreview] = useState(false);
  const [raddedEnsPrd, setraddedEnsPrd] = useState([]);
  const [topic, setTopic] = useState("");
  const [question, setQuestion] = useState("");
  const [requestMessage, setRequestMessage] = useState("");
  const [topicErr, setTopicErr] = useState(false);
  const [questionErr, setQuestionErr] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showdeleteerr, showDeleteErr] = useState(false);
  const [showdeletsucc, showDeleteSucc] = useState(false);
  const [isLoader, setisLoader] = useState(false);
  const [showConfrimAll, setshowConfrimAll] = useState(false);
  const [sonfirmallMessg, setConfirmallMessg] = useState("");
  const [quotepathfile, setQuotepathfile] = useState("");
  const [quotefile, setQouteFile] = useState("");
  const [emptyQuotefile, setEmptyQuotefile] = useState(false);
  const [isshowCOntactmsg, setisshowCOntactmsg] = useState(false);
  const [popData, setPopData] = useState([]);
  const [isModalMinimise, setIsModalMinimize] = useState(false);
  const [enhImageModalLabel, setenhImageModalLabel] = useState(false);
  const [enhImgesArr, setenhImgesArr] = useState([]);
  const [videos, setVideos] = useState([]);
  const [infos, setInfos] = useState([]);
  const [enhAddedamt, setEnhAddedamt] = useState(0);
  const [handelAddedamt, setHandelAddedamt] = useState(0);
  const [addedHandel, setaddedHandel] = useState([]);
  const [enhamtArr, setenhamtArr] = useState([]);
  const [addedEnsPrd, setaddedEnsPrd] = useState([]);
  const [handelamtArr, seteHanelamtArr] = useState([]);
  const [prdiamge, setPrdImage] = useState("");
  const [imgprdGallery, setimgprdGallery] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const options = {
    margin: 45,
    responsiveClass: true,
    nav: false,
    dots: true,
    loop: false,
    autoplay: false,
    animateOut: true,
    animateIn: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      480: {
        items: 1,
        margin: 10,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };

  const openPrimg = (prdImg) => {
    setPrdImage(prdImg);
  };

  const SelectedProductModal = (prd, isVal) => {
    showDeleteErr(false);
    showDeleteSucc(false);
    setselectedProduct(prd);
    setRequestMessage("");
    if (isVal == 1) {
      setRequestModal(true);
    } else {
      setDeleteModal(true);
    }
  };

  const GetQuote = async () => {
    setisLoader(true);
    const data = {};
    data.QuoteId = QuoteId;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`,
    };
    await axios(option)
      .then((e) => {
        setisLoader(false);
        let prevVal = [];

        prevVal = e?.data?.data;

        setQouteData(e?.data?.data);
        setQouteFile(e?.data?.data?.quotePath);
        let prervVal = [];
        if (prevVal.products) {
          let prdStat = prevVal.products;
          for (var i = 0; i < prdStat.length; i++) {
            if (prdStat[i].status == "C" || prdStat[i]?.status == "A") {
              prervVal.push(prdStat[i].quoteProductId);
            }
          }
          setraddedEnsPrd(prervVal);
        }
        if (prevVal?.status === "C") {
          setCpactive(true);
        }
        let prervdVal = [];
        let prervVald = [];

        let prehandelVal = [];
        let enhamtHandelAmt = [];

        if (prevVal.products) {
          let prdStat = prevVal.products;
          let preEnamot = enhAddedamt;
          let preEnamotd = 0;
          let enhamtArrAmt = [];
          let preHanamotd = 0;

          for (var i = 0; i < prdStat.length; i++) {
            let endMdt = 0;
            for (var j = 0; j < prdStat[i].productEnhancementArr.length; j++) {
              if (prdStat[i].productEnhancementArr[j].status == "A") {
                prervdVal.push(
                  prdStat[i].productEnhancementArr[j].enhancementId +
                  "-" +
                  prdStat[i].productEnhancementArr[j].productId
                );
                preEnamotd =
                  preEnamotd +
                  prdStat[i].productEnhancementArr[j].price *
                  prdStat[i].productEnhancementArr[j].qty;
                enhamtArrAmt[prdStat[i].productId] =
                  prdStat[i].productEnhancementArr[j].price *
                  prdStat[i].productEnhancementArr[j].qty;
                endMdt =
                  endMdt +
                  prdStat[i].productEnhancementArr[j].price *
                  prdStat[i].productEnhancementArr[j].qty;
              }
            }

            enhamtArrAmt[prdStat[i].productId] = endMdt;

            if (prdStat[i].selectedHandleOption.length > 0) {
              let hndAmt = 0;
              for (var k = 0; k < prdStat[i].selectedHandleOption.length; k++) {
                if (prdStat[i].selectedHandleOption[k].status == "A") {
                  prehandelVal.push(
                    prdStat[i].selectedHandleOption[k].productId +
                    "-" +
                    prdStat[i].selectedHandleOption[k].id
                  );
                  preHanamotd =
                    preHanamotd +
                    prdStat[i].selectedHandleOption[k].price *
                    prdStat[i].selectedHandleOption[k].qty;
                  hndAmt =
                    hndAmt +
                    prdStat[i].selectedHandleOption[k].price *
                    prdStat[i].selectedHandleOption[k].qty;
                }
              }
              enhamtHandelAmt[prdStat[i].productId] = hndAmt;
            }
          }

          if (prevVal?.status == "C") {
            setCpactive(true);
          }

          setHandelAddedamt(preHanamotd);
          setaddedHandel(prehandelVal);

          setEnhAddedamt(preEnamotd);

          setaddedEnsPrd(prervdVal);

          seteHanelamtArr(enhamtHandelAmt);
          setenhamtArr(enhamtArrAmt);
        }
      })
      .catch((err) => {
      });
  };

  const getQuoteFile = async () => {
    setisLoader(true);
    const data = {};
    if (quotefile == "") {
      setEmptyQuotefile(true);
      setQuotepathfile("No Quote PDF has been found");
    }
    data.QuoteId = QuoteId;
    data.FilePath = quotefile;
    //data.isRequestedByUser = true;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${AdminApiUrl()}/sign/GetQuoteFile`,
    };
    await axios(option)
      .then((e) => {
        setisLoader(false);
        setQuotepathfile(e?.data?.data);
      })
      .catch((err) => {
        setisLoader(false);
      });
  };
  const saveProduct = (item) => {
    const data = {};
    let prevVal = raddedEnsPrd;
    setisLoader(true);
    if (prevVal.includes(item.quoteProductId)) {
      const index = prevVal.indexOf(item.quoteProductId);
      prevVal.splice(index, 1);
      data.Status = "V";

    } else {
      prevVal.push(item.quoteProductId);
      data.Status = "C";
    }

    setraddedEnsPrd([]);
    setraddedEnsPrd(prevVal);

    setPactive((prevState) => !prevState);
    data.QuoteId = QuoteId;
    data.ProductId = item.quoteProductId;
    data.Qty = 0;

    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteProduct`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        if (e?.data.data == 2) {
          let prevVal = raddedEnsPrd;
          prevVal.push(item.quoteProductId);
          showDeleteErr(true);
          setraddedEnsPrd([]);
          setraddedEnsPrd(prevVal);
        }
        GetQuote();
      })
      .catch((err) => {
      });
  };

  const confirmAll = () => {
    const data = {};
    let prevVal = [];
    setraddedEnsPrd([]);
    let allProducts = qouteData?.products;

    if (allProducts.length > 0) {
      for (var i = 0; i < allProducts.length; i++) {
        prevVal.push(allProducts[i].productId);
      }
    }
    setisLoader(true);
    setraddedEnsPrd(prevVal);

    setCpactive((prevState) => !prevState);
    data.QuoteId = QuoteId;
    data.ProductId = 0;
    data.Status = "C";
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteProduct`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        setConfirmallMessg("All the quote products have been approved.");
        GetQuote();
      })
      .catch((err) => {
      });
  };

  const showConfirmall = () => {
    setConfirmallMessg("");
    setshowConfrimAll(true);
  };
  const showContMsg = () => {
    setisshowCOntactmsg(true);
  };

  const closeModal = () => {
    setshowConfrimAll(false);
    showDeleteErr(false);
  };

  const closdeModal = () => {
    setshowConfrimAll(false);
    setisshowCOntactmsg(true);
  };

  const closepdfModal = () => {
    setQuotepathfile("");
  };

  const showenhanImages = (images) => {
    let imgsAr = [];
    for (var i = 0; i < images.length; i++) {
      imgsAr.push(images[i]);
    }
    setenhImgesArr(imgsAr);
    setIsModalMinimize(!isModalMinimise);
    setenhImageModalLabel(true);
  };

  const showPopData = (products) => {
    setPopData(products);
    setIsModalMinimize(!isModalMinimise);
    setenhImageModalLabel(true);
  };

  const showPopImages = (images) => {
    let imgsAr = [];
    for (var i = 0; i < images.length; i++) {
      imgsAr.push(images[i]);
    }
    setenhImgesArr(imgsAr);
    setIsModalMinimize(!isModalMinimise);
    setenhImageModalLabel(true);
  };

  const showPopVideos = (videos) => {
    setVideos(videos);
  };
  const showPopInfo = (info) => {
    let imgsAr = [];
    if (info.length > 0) {
      setInfos(info);
    }
  };

  const deleteProduct = (id) => {
    const data = {};
    setisLoader(true);
    data.QuoteId = QuoteId;
    data.ProductId = id;
    data.Status = "D";
    data.Qty = 0;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteProduct`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        let res = 0;
        if (e?.data.data == 2) {
          res = 1;
        }
      })
      .catch((err) => {
        let res = 1;
        return res;
      });
  };

  const sendRequest = () => {
    setTopicErr(false);
    setQuestionErr(false);
    if (topic == "") {
      setTopicErr(true);
      return;
    }
    if (question == "") {
      setQuestionErr(true);
      return;
    }
    setisLoader(true);
    const data = {};
    data.QuoteId = QuoteId;
    data.ProductId = SelectedProduct.productId;
    data.Topic = topic;
    data.Question = question;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/MakeRequest`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        if (e?.data?.statusCode == 200) {
          setRequestMessage("Your request has been seccessfully submitted!");
        } else {
          setRequestMessage(
            "Something went wrong. Please contact to admin support!"
          );
        }
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    GetQuote();
    AOS.init({ duration: 2000 });

    let prevMode = localStorage.getItem("previewMode");
    if (prevMode == "preview") {
      setCpactive(true);
      setIspreview(true);
    }
  }, []);

  const printPdf = (pdfFile) => {
    window.open(pdfFile, "PRINT", "height=400,width=600");
  };

  const numberWithCommas = (x) => {
    var val = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return val;
  };

  const openGPrimg = (prdImg) => {
    setimgprdGallery(prdImg);
  };

  const closeprdImg = () => {
    setimgprdGallery("");
  };

  return (
    <motion.div className="container-fluid">
      <div className="container-flduid">
        <div className="row">
          <div className="col-md-3 col-lg-2 px-0">
            <Sidebar />
          </div>

          {!isshowCOntactmsg && (
            <div className="col-md-9 col-lg-10  review-page position-relative">
              <div className="main-content align-items-start py-5 flex-column">
                <div
                  className="bg-image"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  {" "}
                  <img
                    src={qouteData?.coverImagePath}
                    alt="img"
                    className="bg-img img-fluid"
                  />
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-10">
                      <div className="estimate-content-section project_vs_left small_box_review">
                        <div className="ecs-fixed-header">
                          <ul className="estimate-heading-list">
                            <li className="">
                              <div className="d-lg-flex justify-content-lg-between align-items-centera">
                              <div className="estimate-left-sec top_estimated_vs">
                                <h3>{qouteData?.title}</h3>
                                <p>Site address : {qouteData?.siteAddress}</p>
                                <div className="customer_detailss flex-coloumn">
                                  <p>{qouteData?.customerName}</p>
                                  <p>{qouteData?.customerEmail}</p>
                                </div>
                              </div>
                              <div className="estimate-right-sec project_vs_right quotenom">
                                <h3>{qouteData?.salesForceRefNo}</h3>
                              </div>     
                              </div>
                              <div className="estimate-right-sec project_vs_right text-right">
                                <p className='top_price_schroll bottom_total_project'><span>Total : </span> £{numberWithCommas((qouteData?.totalAmt + parseFloat(enhAddedamt) + handelAddedamt).toFixed(2))} + {qouteData?.vat} % VAT </p>
                              </div>
                            </li>
                             
                          </ul>
                        </div>
                        <div className="body_product_box">
                          <h5> View your project </h5>
                        </div>
                        <div className="shutter-sec">
                          <div className="colapsible-icon">
                            <Link to="/project">
                              {" "}
                              <img
                                src={require("../../assets/images/arrow-right-vs.png")}
                                alt="gallery_vs"
                              />{" "}
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-2 position-relative">
                      <div className="videos_right_buttons hidden_mobile_btn_sec">
                        <a
                          onClick={() => showPopData(qouteData?.products)}
                          className="btn img-popup-btn"
                          data-bs-toggle="modal"
                          href="#modal-case-study"
                          role="button"
                        >
                          {" "}
                          <img
                            src={require("../../assets/images/gallery_vs.png")}
                            alt="gallery_vs"
                            className="img-fluid"
                          />{" "}
                          Products gallery{" "}
                        </a>
                        <a
                          onClick={() => showPopData(qouteData?.products)}
                          className="btn play-popup-btn"
                          data-bs-toggle="modal"
                          href="#video-slider-modal"
                          role="button"
                        >
                          {" "}
                          <img
                            src={require("../../assets/images/play_vs.png")}
                            alt="play_vs"
                            className="img-fluid"
                          />{" "}
                          Case Study Videos{" "}
                        </a>
                        <a
                          onClick={() => showPopData(qouteData?.products)}
                          className="btn info-popup-btn"
                          data-bs-toggle="modal"
                          href="#exampleModalToggle3"
                          role="button"
                        >
                          {" "}
                          <img
                            src={require("../../assets/images/info_vs.png")}
                            alt="info_vs"
                            className="img-fluid"
                          />{" "}
                          Additional Resources{" "}
                        </a>
                        <a data-bs-toggle="modal" href="#exampleModalToggle5" role="button" className="btn pdf_doc_pop"> <img src={require('../../assets/images/pdfac.png')} alt="gallery_vs" className="img-fluid" /> Additional PDF Docs </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid px-md-0">
                  {qouteData?.products && (
                    <OwlCarousel
                      className="owl-theme review-carousel "
                      data-aos="fade-left"
                      data-aos-duration="800"
                      {...options}
                    >
                      {qouteData?.products?.map((pro, index) => {
                        return (
                          <div key={index} className="quote-confirm-sec">
                            <div className="quote-confirm-box">
                              {pro?.coverImagePath ? (
                                <div className="img-outer-cus">
                                  <a
                                    className="modal_product_video"
                                    onClick={() =>
                                      openPrimg(pro?.coverImagePath)
                                    }
                                    data-bs-toggle="modal"
                                    href="#exampleModalToggle"
                                    role="button"
                                  >
                                    <img
                                      src={pro?.coverImagePath}
                                      alt="img"
                                      className="wc-video img-fluid"
                                      id="pr-img"
                                    />
                                  </a>
                                </div>
                              ) : (

                                ""
                              )}

                              <div className="schroll_product_discrp">
                                <div className="qc-header">
                                  <h3 className="title">{pro?.productName}</h3>
                                </div>
                                <div className="qc-des">
                                  {pro?.lineItems.length > 0 && (
                                    <div className={`left_detail_product`}>
                                      <div className="window_ref_sec">
                                        <div className="inlie_flex_item">
                                          {pro?.lineItems?.map(
                                            (lnItem, index) => {
                                              return (
                                                <div key={index} className="inlie_flex_inner_item">
                                                  <h5 className="ref_window_name">
                                                    <span>
                                                      {" "}
                                                      {lnItem.refId}{" "}
                                                    </span>{" "}
                                                  </h5>
                                                  <h5 className="dims_window_ref">
                                                    <span>
                                                      {" "}
                                                      {lnItem.dimension}{" "}
                                                    </span>{" "}
                                                  </h5>
                                                  <h5 className="last_items_total_price">
                                                    <span>
                                                      {" "}
                                                      £
                                                      {numberWithCommas(
                                                        lnItem.cost.toFixed(2)
                                                      )}{" "}
                                                      + VAT
                                                    </span>{" "}
                                                  </h5>
                                                  <h5 className="qty_window_ref">
                                                    <span> x{lnItem.qty} </span>{" "}
                                                  </h5>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="qc-button">
                                    {!cpactive && (
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => saveProduct(pro)}
                                        className={`btn btn-confirm ${raddedEnsPrd.includes(pro?.quoteProductId)
                                          ? "active"
                                          : ""
                                          }`}
                                        role="button"
                                      >
                                        {
                                          raddedEnsPrd.includes(pro?.quoteProductId)
                                            ? "Selected"
                                            : "Not-Selected"
                                        }
                                        {/* {pro?.status === "V"
                                          ? "Not Selected"
                                          : "Selected"} */}
                                      </a>
                                    )}

                                    {cpactive && (
                                      <a
                                        href="javascript:void(0)"
                                        className={`btn btn-confirm btn_sected_all disable_product_select ${raddedEnsPrd.includes(pro?.productId)
                                          ? "active"
                                          : ""
                                          }`}
                                        role="button"
                                      >
                                        {raddedEnsPrd.includes(pro?.productId)
                                          ? "Selected"
                                          : "Selected"}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="price_section">
                                <h3 className="price">
                                  £
                                  {numberWithCommas(
                                    (
                                      pro?.totalAmt +
                                      (enhamtArr[pro?.productId]
                                        ? enhamtArr[pro?.productId]
                                        : 0) +
                                      (handelamtArr[pro?.productId]
                                        ? handelamtArr[pro?.productId]
                                        : 0)
                                    ).toFixed(2)
                                  )}{" "}
                                  + VAT{" "}
                                </h3>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  )}
                </div>
                <section className="price-section-reviews">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-4 d-md-flex align-items-center py-md-5 py-3">
                        <div className="contact-no">
                          <h3>
                            {" "}
                            <img
                              src={require("../../assets/images/review_printer_icon.png")}
                              alt="printer-icon-review"
                              className="icon_review_print img-fluid"
                            />{" "}
                            <a
                              href="javascript:void(0)"
                              onClick={() => getQuoteFile()}
                              role="button"
                            >
                              Print your selected Quote
                            </a>
                          </h3>
                        </div>
                      </div>
                      <div className="total-estimate-sec col-md-12 col-lg-6 d-flex align-items-start py-md-5 py-3">
                        <div className="price-label">
                          <h1>Your Total: </h1>
                        </div>
                        {qouteData?.totalAmt && (
                          <div className="price-amount">
                            <h1>
                              £
                              {numberWithCommas(
                                (
                                  qouteData?.totalAmt +
                                  qouteData?.totalEnhancementAmt +
                                  qouteData?.totalHandleOptionAmt
                                ).toFixed(2)
                              )}{" "}+ {qouteData?.vat}% VAT {" "}
                            </h1>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-2 col-md-12 d-flex justify-content-center align-items-center py-md-5 py-3">
                        <div className="approved_button_right">
                          {cpactive && !ispreview && (
                            <button
                              className={`button-lottie-ani border-0 bg-transparent gray-lottie-btn ${cpactive ? "active" : ""
                                }`}
                            >
                              <Lottie
                                options={defaultOptions}
                                height={40}
                                width={150}
                                isStopped={false}
                                isPaused={false}
                              />
                              {qouteData.quoteContractInfoModel?.isSent !=
                                true && (
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => showContMsg()}
                                    className="btn btn-primary next-btn bg-gray-btn confirm-all-btn-main"
                                    role="button"
                                  >
                                    Next
                                  </a>
                                )}
                              {qouteData.quoteContractInfoModel?.isSent ==
                                true && (
                                  <Link
                                    to="/terms&Sign"
                                    className="btn btn-primary next-btn bg-gray-btn confirm-all-btn-main"
                                    role="button"
                                  >
                                    Next
                                  </Link>
                                )}
                            </button>
                          )}
                          {!cpactive && (
                            <button
                              className={`button-lottie-ani border-0 bg-transparent gray-lottie-btn active`}
                            >
                              <Lottie
                                options={defaultOptions}
                                height={40}
                                width={150}
                                isStopped={false}
                                isPaused={false}
                              />
                              <a
                                href="javascript:void(0)"
                                onClick={() => showConfirmall()}
                                className="btn btn-primary next-btn bg-gray-btn confirm-all-btn-main"
                                role="button"
                              >
                                Approve
                              </a>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )}

          {isshowCOntactmsg && (
            <div className="main-content align-items-start py-5 flex-column cobtr_msg_sec">
              <div
                className="bg-image"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1000"
              >
                {" "}
                <img
                  src={qouteData?.coverImagePath}
                  alt="img"
                  className="bg-img img-fluid"
                />
              </div>

              {qouteData.quoteContractInfoModel?.isSent != true && (
                <div>
                  <div className="sign_contract_center">
                    {" "}
                    <h2>
                      Thank you for approving your Quotation. <br />{" "}
                      {qouteData?.salesPersonName} will be in touch shortly to
                      discuss your contract.
                    </h2>
                  </div>
                  <div className="sign_contract_buttons outer_disable_sing">
                    <a
                      className="sing_contact_btn disable_cont_btn"
                      href="javascript:"
                    >
                      Sign Contract
                    </a>
                  </div>
                </div>
              )}

              {qouteData.quoteContractInfoModel?.isSent == true && (
                <div>
                  <div className="sign_contract_center">
                    {" "}
                    <h2>
                      Your contract has been created. <br /> Please approve and
                      sign your contract.
                    </h2>
                  </div>
                  <div className="sign_contract_buttons">
                    <Link
                      to="/terms&Sign"
                      className="sing_contact_btn"
                      role="button"
                    >
                      Sign Contract
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
          {isLoader && (
            <div className="loader-img-outer">
              <img src={LoaderImg} className="loader_img_cont" />{" "}
            </div>
          )}
        </div>
        <div
          className={`modal blur-overlay fade print-popup-overlady print-popup-overlay ${quotepathfile == "" ? "d-none" : ""
            }`}
          id="exampleModalToggle1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                onClick={() => closepdfModal()}
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ backgroundImage: `url(${background})` }}
              ></button>
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="quote-image-sec">
                      <img
                        src={qouteData?.coverImagePath}
                        alt="img"
                        className="logoforpopup img-fluid"
                      />
                      <div className="qis-content">
                        <h5>Your Quote</h5>
                        <img
                          src={require("../../assets/images/logo.png")}
                          alt="img"
                          className="logoforpopup img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  {quotepathfile != "" && !emptyQuotefile && (
                    <div className="col-sm-6">
                      <h1>Print a copy</h1>
                      <p>Click print or download to receive your pdf copy </p>

                      <a
                        target="_blank"
                        href={quotepathfile}
                        className="btn btn-primary download-btn"
                        download
                      >
                        Download
                      </a>
                      <a
                        href="javascript:void(0)"
                        onClick={() => printPdf(quotepathfile)}
                        className="btn btn-primary print-btn"
                        role="button"
                      >
                        Print
                      </a>
                    </div>
                  )}
                  {quotepathfile != "" && emptyQuotefile && (
                    <div className="col-sm-6">
                      <h1>No document is ready for download yet!</h1>
                    </div>
                  )}

                  {quotepathfile == "" && (
                    <div className="col-sm-6">
                      <h1>No document is ready for download yet!</h1>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal blur-overlay fade cinfo-popup-overlay info-popup  ${!showConfrimAll ? "d-none" : ""
            }`}
          id="confimAllReqModal"
          tabIndex="-1"
          aria-labelledby="confimAllReqModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-12">
                    {sonfirmallMessg == "" && (
                      <div className="popup-request-form">
                        <h2>
                          <span>
                            Are you sure you want to approve all selected
                            products?
                          </span>
                        </h2>
                        <a
                          id="closemodal"
                          href="javascript:void(0)"
                          onClick={() => closeModal()}
                          className="btn btn-primary download-btn close"
                          data-dismiss="confimAllReqModal"
                        >
                          Cancel
                        </a>

                        <a
                          href="javascript:void(0)"
                          onClick={() => confirmAll()}
                          className="btn btn-primary print-btn"
                          role="button"
                        >
                          Approve
                        </a>
                      </div>
                    )}

                    {sonfirmallMessg != "" && (
                      <div className="popup-request-form">
                        <h2>
                          <span>{sonfirmallMessg}</span>{" "}
                        </h2>
                        <a
                          id="closemodal"
                          href="javascript:void(0)"
                          onClick={() => closdeModal()}
                          className="btn btn-primary download-btn close"
                          data-dismiss="confimAllReqModal"
                        >
                          Close
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal blur-overlay fade cinfo-popup-overlay info-popup  ${!showdeleteerr ? "d-none" : ""
            }`}
          id="deleteReqModal"
          tabIndex="-1"
          aria-labelledby="deleteReqModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-12">
                    <h2 className="my-0">
                      <span className="text-center d-block">
                        You cannot delete the product since you have left only
                        one product in the quote.
                      </span>
                    </h2>
                    <a
                      id="closemodal"
                      href="javascript:void(0)"
                      onClick={() => closeModal()}
                      className="my-0 btn btn-primary download-btn close"
                      data-dismiss="confimAllReqModal"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal blur-overlay fade request-form-overlay"
          id="requestModalToggle"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="rf-left-sec">
                      {SelectedProduct && (
                        <div className="quote-confirm-box">
                          <div className="qc-header">
                            <h3 className="title">
                              {SelectedProduct?.productName}
                            </h3>
                            <h3 className="price">
                              £{SelectedProduct?.price.toFixed(2)}
                            </h3>
                          </div>
                          {SelectedProduct?.coverImagePath ? (
                            <div className="inner_imge_cust">
                              <img
                                src={SelectedProduct?.coverImagePath}
                                alt="img"
                                className="wc-video img-fluid"
                                id="pr-img"
                              />
                            </div>
                          ) : (
                            <div className="inner_imge_cust">
                              <img
                                src="images/Image_not_available.png"
                                alt="img"
                                className="wc-video img-fluid"
                                id="pr-img"
                              />
                            </div>
                          )}

                          <div className="qc-des">
                            <p>{SelectedProduct?.productAttStr}</p>
                            <ul className="qc-list">
                              {SelectedProduct?.totalAddedEnhancementCount !=
                                0 && (
                                  <li>
                                    {SelectedProduct?.totalAddedEnhancementCount}{" "}
                                    x enhancements added
                                  </li>
                                )}
                              {SelectedProduct?.totalAddedEnhancementCount ==
                                0 && <li>No enhancements added</li>}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <form>
                      {requestMessage == "" && (
                        <div className="popup-request-form">
                          <h1>Request form</h1>

                          <input
                            type="text"
                            name="topic_section"
                            className="form-control"
                            onChange={(topic) => setTopic(topic.target.value)}
                            placeholder="Topic..."
                            required
                          />
                          {topicErr && (
                            <span className="err">
                              Please write about your topic!
                            </span>
                          )}
                          <textarea
                            id="w3review"
                            name="w3review"
                            rows="4"
                            cols="50"
                            onChange={(question) =>
                              setQuestion(question.target.value)
                            }
                            placeholder="Question..."
                            required
                          ></textarea>
                          {questionErr && (
                            <span className="err">
                              Please write your question!
                            </span>
                          )}
                          <a
                            href="javascript:void(0)"
                            className="btn btn-primary send-btn"
                            role="button"
                            onClick={() => sendRequest()}
                          >
                            Send
                          </a>
                        </div>
                      )}
                      {requestMessage != "" && (
                        <div className="popup-request-form">
                          <h2>{requestMessage}</h2>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className='row'>
                <div className="col-9">
                  <div className='video_heading_modal'>
                    <h3> Additional PDF Documents </h3>
                  </div>
                </div>
                <div className='col-3'>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                </div>
              </div>

              <div className="modal-body">
                <div className='pdf_document_main abg'>
                  <div className='pdf_schroll_top'>
                    <ul className="pdf_schroll_slider">

                      {
                        qouteData?.additionalPdfPath?.map((adpdf, index) => {
                          return (
                            <li key={index}><a target="_blank" href={adpdf.path}>
                              <img src={require('../../assets/images/PDF_32_vs.png')} alt="product_banner_SF55" className="img-fluid" />
                              <b> {adpdf.fileName} </b> </a></li>
                          )
                        })
                      }

                    </ul>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal project-gallery-modal onep  ${imgprdGallery == '' ? 'd-none' : ''}`} id="prd_gallery_image" tabIndex="-1" aria-labelledby="deleteReqModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">


              <button type="button" onClick={() => closeprdImg()} className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
              <div className="modal-body btn p-0">
                <img src={imgprdGallery} alt="img" className="pp-video img-fluid" />

              </div>
            </div>
          </div>
        </div>

        <div
          className="modal blur-overlay fade request-form-overlay request-form-delete"
          id="deleteModalToggle"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-6">
                    <div className="rf-left-sec">
                      {SelectedProduct && (
                        <div className="quote-confirm-box">
                          <div className="qc-header">
                            <h3 className="title">
                              {SelectedProduct?.productName}
                            </h3>
                            <h3 className="price">
                              £{SelectedProduct?.price.toFixed(2)}
                            </h3>
                          </div>
                          {SelectedProduct?.coverImagePath ? (
                            <div className="inner_imge_cust">
                              <img
                                src={SelectedProduct?.coverImagePath}
                                alt="img"
                                className="wc-video img-fluid"
                                id="pr-img"
                              />
                            </div>
                          ) : (
                            <div className="inner_imge_cust">
                              <img
                                src="images/Image_not_available.png"
                                alt="img"
                                className="wc-video img-fluid"
                                id="pr-img"
                              />
                            </div>
                          )}
                          <div className="qc-des">
                            <p>{SelectedProduct?.productAttStr}</p>
                            <ul className="qc-list">
                              {SelectedProduct?.totalAddedEnhancementCount !=
                                0 && (
                                  <li>
                                    {SelectedProduct?.totalAddedEnhancementCount}{" "}
                                    x enhancements added
                                  </li>
                                )}
                              {SelectedProduct?.totalAddedEnhancementCount ==
                                0 && <li>No enhancements added</li>}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    {!showdeleteerr && (
                      <div className="popup-request-form">
                        <h2>
                          Warning <br />
                          {!showdeletsucc && (
                            <span>
                              Are you sure you want to remove this from your
                              quote?
                            </span>
                          )}
                          {showdeletsucc && (
                            <span>
                              Quote product has been deleted successfully?
                            </span>
                          )}
                        </h2>

                        {!showdeletsucc && (
                          <a
                            href="javascript:void(0)"
                            onClick={() =>
                              deleteProduct(SelectedProduct?.productId)
                            }
                            className="btn btn-primary delete-btn"
                            role="button"
                          >
                            Delete
                          </a>
                        )}
                      </div>
                    )}
                    {showdeleteerr && (
                      <div className="popup-request-form">
                        <h2>
                          You cannot delete the product since you have left only
                          one product in th quote.
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal blur-overlay fade info-popup-overlay"
          id="exampleModalToggle3"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="row">
                <div className="col-9">
                  <div className="video_heading_modal">
                    <h3> Additional Product Resources </h3>
                  </div>
                </div>
                <div className="col-3">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ backgroundImage: `url(${background})` }}
                  ></button>
                </div>
              </div>

              <div className="modal-body">
                {popData && (
                  <div className="row">
                    {popData.map((pro, index) => {
                      return (
                        <div key={index} className="col-12">
                          <div className="pdf_document_main modal_project_pdf">
                            <h3> {pro?.productName} </h3>
                            {pro?.media?.info.length > 0 && (
                              <div className="pdf_schroll_top">
                                <ul className="pdf_schroll_slider modal_pdf_products">
                                  {pro?.media?.info.map((info, index) => {
                                    return (
                                      <li key={index}>
                                        <a href={info.path} target="_blank">
                                          <img
                                            className="img-fluid"
                                            alt="{info.fileName}"
                                            src={PagesSvg.pdfIconSvg}
                                          />{" "}
                                          <b> {info.fileName} </b>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                            {pro?.media?.info.length == 0 && (
                              <div className="pdf_schroll_top">
                                <span className="no_video_lable">
                                  No additional product resources found
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal blur-overlay videos-slider-popup-overlay"
          id="modal-case-study"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              {popData && (
                <div className="gallery">
                  {popData.map((pro, index) => {
                    return (
                      <div key={index} className="row">
                        <div className="col-9">
                          <div className="video_heading_modal">
                            <h3> {pro?.productName} </h3>
                          </div>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ backgroundImage: `url(${background})` }}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row align-items-center">
                            <div className="col-12">
                              {pro?.selectedPrintPdfPath.length > 0 && (
                                <div className="main_scroll_product">
                                  {pro?.selectedPrintPdfPath.map(
                                    (imager, index) => {
                                      return (
                                        <div key={index} className="outer_videos_modal">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => openGPrimg(imager)}
                                          >
                                            <img
                                              src={imager}
                                              alt="gallery_vs"
                                            />{" "}
                                          </a>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                              {pro?.selectedPrintPdfPath.length == 0 && (
                                <div className="main_scroll_product">
                                  {" "}
                                  <span className="no_video_lable">
                                    No gallery images found
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="modal video-play-modal onep"
          id="exampleModalToggle"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content white-blur-bg">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ backgroundImage: `url(${background})` }}
              ></button>
              <div className="modal-body btn p-0">
                <img src={prdiamge} alt="img" className="pp-video img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal blur-overlay videos-slider-popup-overlay"
          id="video-slider-modal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              {popData && (
                <div className="gallery">
                  {popData.map((pro, index) => {
                    return (
                      <div key={index} className="row">
                        <div className="col-9">
                          <div className="video_heading_modal">
                            <h3> {pro?.productName} </h3>
                          </div>
                        </div>
                        <div className="col-3">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ backgroundImage: `url(${background})` }}
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row align-items-center">
                            <div className="col-12">
                              {pro?.media?.videos.length > 0 && (
                                <div className="main_scroll_product">
                                  {pro?.media?.videos.map((videor, index) => {
                                    return (
                                      <div key={index} className="outer_videos_modal">
                                        <video
                                          width="250"
                                          height="210"
                                          controls
                                        >
                                          <source
                                            src={videor.path}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag. I suggest you upgrade your
                                          browser.
                                        </video>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {pro?.media?.videos.length == 0 && (
                                <div className="main_scroll_product">
                                  {" "}
                                  <span className="no_video_lable">
                                    No videos found
                                  </span>{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>s
          </div>
        </div>
      </div>
    </motion.div>
  );
}
export default Review;
