import React, { Component, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet, Link,NavLink  } from "react-router-dom";
import CommonSvg from "../Constant/CommonSvg";
import PropTypes from 'prop-types';
import { ApiUrl, GetQuoteId } from '../../utils/utils';
import axios from 'axios';


const Sidebar = () => {
  const [qouteData, setQouteData] = useState('');
  const[isLoader, setisLoader] = useState(false);
  const[isTerm, SetisTerm] = useState(false);
  const[isPayment, SetisPayment] = useState(false);
  const[isProgress, SetisProgress] = useState(false);
  const[teamSvgimage,setteamSvgimage] = useState(CommonSvg.TeamSvg);
  const[projectSvgimage,setprojectSvgimage] = useState(CommonSvg.GlassSvg);
  const[reviewSvgimage,setreviewSvgimage] = useState(CommonSvg.ReviewSvg);
  const[termSvgimage,settermSvgimage] = useState(CommonSvg.TermsSvg);
  const[paymentSvgimage,setpaymentSvgimage] = useState(CommonSvg.PaymentsSvg);
  const[progressSvgimage,setprogressSvgimage] = useState(CommonSvg.ProgressSvg);
  const[makerequstgimage,semakerequstgimage] = useState(CommonSvg.MakerequestImg);

  const[teamaSvgimage,setateamSvgimage] = useState('');
  const[projectaSvgimage,setaprojectSvgimage] = useState('');
  const[reviewaSvgimage,setareviewSvgimage] = useState('');
  const[amakerequstgimage,setamakerequstgimage] = useState('');
  const[termaSvgimage,setatermSvgimage] = useState('');
  const[paymentaSvgimage,setapaymentSvgimage] = useState('');
  const[progressaSvgimage,setaprogressSvgimage] = useState('');
  const[previewmode,setPreviewmode] = useState(false);
 
  const QuoteId = GetQuoteId();


  const GetQuote = () => {
        setisLoader(true);
        const data = {};
        data.QuoteId = QuoteId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`
        };
        axios(option)
        .then((e) => {
            setisLoader(false);
            setQouteData(e?.data?.data);

            if(e?.data?.data?.quoteContractInfoModel?.isSent == true){
               SetisTerm(true);
            }

            if(e?.data?.data?.quoteContractInfoModel?.isSent == true){
               SetisPayment(true);
            }

           
               SetisProgress(true);
            
        })
        .catch((err) => {
        })
    }

    let location = useLocation();

    useEffect(() => {
        GetQuote();

        let preM = localStorage.getItem('previewMode');
        if(preM == 'preview'){
          setPreviewmode(true);
        }
        
        const locationPath = location.pathname;
        if(locationPath == '/team'){
          setateamSvgimage(CommonSvg.TeamHoverSvg);
        }
        if(locationPath == '/project'){
          setaprojectSvgimage(CommonSvg.GlassSvgHover);
        }
        if(locationPath == '/review'){
          setareviewSvgimage(CommonSvg.ReviewSvgHover);
        }
        if(locationPath == '/make-request'){
          setamakerequstgimage(CommonSvg.MakerequestsHoverImg);
        }
        if(locationPath == '/terms&Sign'){
          setatermSvgimage(CommonSvg.TermsSvgHover);
        }
        if(locationPath == '/payments'){
          setapaymentSvgimage(CommonSvg.PaymentsSvgHover);
        }
        if(locationPath == '/progress'){
          setaprogressSvgimage(CommonSvg.ProgressSvgHover);
        }
  

    }, [location])

    const handleClick = (e) => {
      
      if(!isTerm){
        e.preventDefault()
        return false;
      } 
      let prevMode = localStorage.getItem('previewMode');
      if(prevMode == 'preview'){
        e.preventDefault()
        return false;
      }
    }

     const handleOtherClick = (e) => {
     
      let prevMode = localStorage.getItem('previewMode');
      if(prevMode == 'preview'){
        e.preventDefault();
        return false;
      }
    }

    const setActiveImage = (isVal) => {
      if(isVal == '1'){
        setteamSvgimage(CommonSvg.TeamHoverSvg);
      }
      if(isVal == '2'){
        setprojectSvgimage(CommonSvg.GlassSvgHover);
      }
      if(isVal == '3'){
        setreviewSvgimage(CommonSvg.ReviewSvgHover);
      }
      if(isVal == '4'){
        settermSvgimage(CommonSvg.TermsSvgHover);
      }
      if(isVal == '5'){
        setpaymentSvgimage(CommonSvg.PaymentsSvgHover);
      }
      if(isVal == '6'){
        setprogressSvgimage(CommonSvg.ProgressSvgHover);
      }
      if(isVal == '7'){
        semakerequstgimage(CommonSvg.MakerequestsHoverImg);
      }
      
    }
    const removeActiveImage = (isVal) => {
      if(isVal == '1'){
        setteamSvgimage(CommonSvg.TeamSvg);
      }
      if(isVal == '2'){
        setprojectSvgimage(CommonSvg.GlassSvg);
      }
      if(isVal == '3'){
        setreviewSvgimage(CommonSvg.ReviewSvg);
      }
      if(isVal == '4'){
        settermSvgimage(CommonSvg.TermsSvg);
      }
      if(isVal == '5'){
        setpaymentSvgimage(CommonSvg.PaymentsSvg);
      }
      if(isVal == '6'){
        setprogressSvgimage(CommonSvg.ProgressSvg);
      }
      if(isVal == '7'){
        semakerequstgimage(CommonSvg.MakerequestImg);
      }
    }


  return (
    <>
    <div className="side-bar">
    <nav className="navbar navbar-expand-md navbar-dark bg-dark p-0">
    <a className="navbar-brand" href="/team"><img src={CommonSvg.Wlogo} alt="icon" className="brand-logo"/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
  <g id="Group_130" data-name="Group 130" transform="translate(-297 -61)">
    <rect id="Rectangle_417" data-name="Rectangle 417" width="7" height="7" transform="translate(297 61)" fill="#fff"/>
    <rect id="Rectangle_418" data-name="Rectangle 418" width="7" height="7" transform="translate(307 61)" fill="#fff"/>
    <rect id="Rectangle_419" data-name="Rectangle 419" width="7" height="7" transform="translate(317 61)" fill="#fff"/>
    <rect id="Rectangle_420" data-name="Rectangle 420" width="7" height="7" transform="translate(317 71)" fill="#fff"/>
    <rect id="Rectangle_421" data-name="Rectangle 421" width="7" height="7" transform="translate(307 71)" fill="#fff"/>
    <rect id="Rectangle_422" data-name="Rectangle 422" width="7" height="7" transform="translate(297 71)" fill="#fff"/>
    <rect id="Rectangle_423" data-name="Rectangle 423" width="7" height="7" transform="translate(317 81)" fill="#fff"/>
    <rect id="Rectangle_424" data-name="Rectangle 424" width="7" height="7" transform="translate(307 81)" fill="#fff"/>
    <rect id="Rectangle_425" data-name="Rectangle 425" width="7" height="7" transform="translate(297 81)" fill="#fff"/>
  </g>
</svg>

    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className={`nav-item ${!previewmode ? 'activemenu' : ''}`} onMouseEnter={() => setActiveImage('1')}
        onMouseLeave={() => removeActiveImage('1')}>

          <NavLink to="/team" className="nav-link" onClick={handleOtherClick} aria-current="page">
          {teamaSvgimage != '' && <img src={teamaSvgimage} alt="icon" />    }
          {teamaSvgimage == '' && <img src={teamSvgimage} alt="icon" />    }
          <span>Your Team</span>
          </NavLink>
        </li>
        <li className={`nav-item activemenu`} onMouseEnter={() => setActiveImage('2')}
        onMouseLeave={() => removeActiveImage('2')}>
          <NavLink to="/project" className="nav-link" aria-current="page">
          {projectaSvgimage != '' && <img src={projectaSvgimage} alt="icon" />    }
          {projectaSvgimage == '' && <img src={projectSvgimage} alt="icon" />    }
         
          <span>Your Project</span>
          </NavLink>
        </li>
        <li className={`nav-item activemenu`} onMouseEnter={() => setActiveImage('3')}
        onMouseLeave={() => removeActiveImage('3')}>
          <NavLink to="/review" onClick={handleOtherClick} className="nav-link" aria-current="page">
         
          {reviewaSvgimage != '' && <img src={reviewaSvgimage} alt="icon" />    }
          {reviewaSvgimage == '' && <img src={reviewSvgimage} alt="icon" />    }
          <span>Refined</span>
          </NavLink>
        </li>
        <li className={`nav-item ${!previewmode ? 'activemenu' : ''}`} onMouseEnter={() => setActiveImage('7')}
        onMouseLeave={() => removeActiveImage('7')}>
          <NavLink to="/make-request" onClick={handleOtherClick} className="nav-link" aria-current="page">
         
          {amakerequstgimage != '' && <img src={amakerequstgimage} alt="icon" />    }
          {amakerequstgimage == '' && <img src={makerequstgimage} alt="icon" />    }
          <span>Make a Request</span>
          </NavLink>
        </li>
         <li className={`nav-item ${isTerm && !previewmode ? 'activemenu' : ''}`} onMouseEnter={() => setActiveImage('4')}
        onMouseLeave={() => removeActiveImage('4')}>
          <NavLink to="/terms&Sign" className={`nav-link `} onClick={handleClick}  aria-current="page">
          
          {termaSvgimage != '' && <img src={termaSvgimage} alt="icon" />    }
          {termaSvgimage == '' && <img src={termSvgimage} alt="icon" />    }
          <span>Contract</span>
          </NavLink>
        </li>
        <li className={`nav-item ${isPayment && !previewmode ? 'activemenu' : ''}`} onMouseEnter={() => setActiveImage('5')}
        onMouseLeave={() => removeActiveImage('5')}>
          <NavLink to="/payments" className={`nav-link`} onClick={handleClick} aria-current="page">
          
          {paymentaSvgimage != '' && <img src={paymentaSvgimage} alt="icon" />    }
          {paymentaSvgimage == '' && <img src={paymentSvgimage} alt="icon" />    }
          <span>Payments</span>
          </NavLink>
        </li>
         <li className={`nav-item ${!previewmode ? 'activemenu' : ''}`} onMouseEnter={() => setActiveImage('6')}
        onMouseLeave={() => removeActiveImage('6')}>
          <NavLink to="/progress" onClick={handleOtherClick} className={`nav-link`} aria-current="page">
          
          {progressaSvgimage != '' && <img src={progressaSvgimage} alt="icon" />    }
          {progressaSvgimage == '' && <img src={progressSvgimage} alt="icon" />    }
          <span>Progress</span>
          </NavLink>
        </li> 
        </ul>

  </div>

{/* <div className="col d-flex align-items-center chatbot-sec py-lg-5 py-3"><div className="chatbot-icon"><a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="41.456" height="41.456" viewBox="0 0 41.456 41.456"><g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" transform="translate(-3.375 -3.375)"><path id="Path_31" data-name="Path 31" d="M39.538,30.541a2.414,2.414,0,0,1,.329-1.216,3.356,3.356,0,0,1,.209-.309,16.147,16.147,0,0,0,2.75-9c.03-9.188-7.723-16.642-17.31-16.642A17.191,17.191,0,0,0,8.546,16.619a16.014,16.014,0,0,0-.369,3.408A16.9,16.9,0,0,0,25.218,36.879a20.523,20.523,0,0,0,4.7-.767c1.126-.309,2.242-.718,2.531-.827a2.635,2.635,0,0,1,.927-.169,2.591,2.591,0,0,1,1.007.2l5.65,2a1.347,1.347,0,0,0,.389.1.793.793,0,0,0,.8-.8,1.28,1.28,0,0,0-.05-.269Z" transform="translate(2.004)"></path><path id="Path_32" data-name="Path 32" d="M30.331,33.73c-.359.1-.817.209-1.315.319a18.393,18.393,0,0,1-3.388.448A16.9,16.9,0,0,1,8.587,17.646a18.841,18.841,0,0,1,.149-2.133c.06-.429.13-.857.229-1.276.1-.448.219-.9.349-1.335l-.8.708A14.83,14.83,0,0,0,3.375,24.761a14.665,14.665,0,0,0,2.471,8.172c.229.349.359.618.319.8s-1.186,6.179-1.186,6.179a.8.8,0,0,0,.269.767.812.812,0,0,0,.508.179.715.715,0,0,0,.289-.06l5.591-2.2a1.557,1.557,0,0,1,1.2.02,16.772,16.772,0,0,0,6.049,1.2A15.651,15.651,0,0,0,30.85,34.318s.319-.438.688-.957C31.168,33.491,30.75,33.62,30.331,33.73Z" transform="translate(0 3.976)"></path></g></svg></a></div></div> */}
<span className="dt_url">Developed by <a href="https://dottechnologies.net/" target="_blank">DOT Technologies</a></span>
</nav>
</div>

 
      <Outlet />
    </>
  )
};

export default Sidebar;