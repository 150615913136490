import React, { Component, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import EstimatesecSvg from "../Constant/EstimatesecSvg";
import background from "../../assets/images/close.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ApiUrl, GetQuoteId } from '../../utils/utils';
import axios from 'axios';
import moment from 'moment/moment';
import './Glasscss.css';
import {Carousel} from '3d-react-carousal';
import { Link } from "react-router-dom";
import Lottie from 'react-lottie';
import PagesSvg from "../Constant/PagesSvg";
import * as animationData from '../../assets/js/7280801.json'
import ReactPlayer from 'react-player'
import LoaderImg from "../../assets/images/loader.gif";


const Glass = () => {
    const QuoteId = GetQuoteId();
    const [welcomeModal, setWelcomeModal] = useState(false);
    const [isModalMinimise, setIsModalMinimize] = useState(false);
    const [enhImageModalLabel, setenhImageModalLabel] = useState(false);
    const [cpactive, setCpactive] = useState(false);
    const [qouteData, setQouteData] = useState('');
    const [enhImgesArr, setenhImgesArr] = useState([]);
    const [addedEnsPrd, setaddedEnsPrd] = useState([]);
    const [videos, setVideos] = useState([]);
    const [infos, setInfos] = useState([]);
    const [enhqty, setEnhQty] = useState([]);
    const dateAdded = moment(qouteData?.createdOn)?.format("DD/MM/YY");
      const [active, setActive] = useState(null)
      const [pactive, setPactive] = useState(null)
      const [enhAddedamt, setEnhAddedamt] = useState(0)
      const[isLoader, setisLoader] = useState(false);
    const welcomeModalHandler = () => {
        setWelcomeModal(true)
    }

    const EventMinimiser = () => {
        setIsModalMinimize(!isModalMinimise)
    }

    const slideToProd = (pId) => {
        const element = document.getElementById(pId+'_mainprd_sec');
        setActive(pId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    

    const showenhanImages = (images) => {
        let imgsAr = [];
        for (var i = 0; i < images.length; i++) {
            imgsAr.push(<img  src={images[i]} alt={i} />);
        }
        setenhImgesArr(imgsAr);
        setIsModalMinimize(!isModalMinimise)
        setenhImageModalLabel(true)
    }

    const showPopImages = (images) => {
        let imgsAr = [];
        for (var i = 0; i < images.length; i++) {
            imgsAr.push(<img  src={images[i].path} alt={i} />);
        }
        setenhImgesArr(imgsAr);
        setIsModalMinimize(!isModalMinimise)
        setenhImageModalLabel(true)
    }

    const showPopVideos = (videos) => {
        
        
        setVideos(videos);
        
    }
    const showPopInfo = (info) => {
            let imgsAr = [];
            if(info.length > 0){
                setInfos(info);
            }
            
        }

    const closeEnhModal =() => {
        setIsModalMinimize(!isModalMinimise);
        setenhImageModalLabel(false)
        setenhImgesArr([]);
    }

    const sliderImg = () => {
        return
    }

    const GetQuote = () => {
        setisLoader(true);
        const data = {};
        data.QuoteId = QuoteId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`
        };
        axios(option)
            .then((e) => {
                setisLoader(false);
                setQouteData(e?.data?.data);
                let pop_status = localStorage.getItem('pop_status');
                  if(!pop_status){
                   
                    localStorage.setItem('pop_status',1);
                  }

                  if(location.state !=null){
                    const pId = location.state.product_id;
                    setTimeout(
                    function() {
                        slideToProd(pId);
                    }
                    .bind(this),
                    500
                    );
                    
                  }
       

                let prervVal = [];
                let prervVald = [];
                let prevVal = [];

                prevVal = e?.data?.data
                if(prevVal.products){
                    let prdStat = prevVal.products;
                    let preEnamot = enhAddedamt;
                    let preEnamotd = 0;
                     for (var i = 0; i < prdStat.length; i++) {
                        for (var j = 0; j < prdStat[i].productEnhancementArr.length; j++) {
                            if(prdStat[i].productEnhancementArr[j].status == 'A'){
                               prervVal.push(prdStat[i].productEnhancementArr[j].enhancementId+'-'+prdStat[i].productEnhancementArr[j].productId);     
                               preEnamotd = preEnamotd+(prdStat[i].productEnhancementArr[j].price*prdStat[i].productEnhancementArr[j].qty);
                               
                            }

                           
                        }
                         if(prdStat[i].status == 'C'){
                               prervVald.push(prdStat[i].productId);     
                            }
                        
                    }
                    if(prervVald.length == prevVal.products.length){
                        setCpactive(true);
                    }


                    setEnhAddedamt(preEnamotd);
                    setaddedEnsPrd(prervVal);
                }
                
            })
            .catch((err) => {
            })
    }

    const changeQty = async (item)=>{
        let qty = document.getElementById(item?.enhancementId+'-'+item?.productId).value; 
        if(qty != ''){
            if(qty == '0'){
                qty = '1';
                document.getElementById(item?.enhancementId+'-'+item?.productId).value = '1';
            }
            let preEnamot = enhAddedamt;
            let prvalll = item.price*item.qty;
            preEnamot = preEnamot-prvalll;
            setEnhAddedamt(preEnamot+item.price*qty);
             const data = {};
            setisLoader(true);
            data.QuoteId = QuoteId;
            data.ProductId = item.productId;
            data.EnhancementId = item.enhancementId;
            data.Qty = qty;
            data.Status = 'A';
           const option = {
                method: 'POST', headers: {
                    "access-control-allow-origin": "*",
                    "content-type": "application/json",
                    "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
                },
                data: data,
                url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteEnhancement`
            };
            await axios(option)
                .then((e) => {
                    setisLoader(false);
                    
                })
                .catch((err) => { 
                })
        }
        
    }

    const saveProduct = async (item) => {
        const data = {};
        setisLoader(true);
        let qty = document.getElementById(item?.enhancementId+'-'+item?.productId).value; 

       
        let prevVal = addedEnsPrd;
        let preEnamot = enhAddedamt;
    
        if(prevVal.includes(item.enhancementId+'-'+item.productId)){
            setEnhAddedamt(preEnamot-(item.price*item.qty));
            const index = prevVal.indexOf(item.enhancementId+'-'+item.productId);
            prevVal.splice(index,1)
            data.Status = 'V';
        }else{
            prevVal.push(item.enhancementId+'-'+item.productId);
            data.Status = 'A';
            setEnhAddedamt(preEnamot+item.price*qty);
        }
        
        setaddedEnsPrd([]);
        setaddedEnsPrd(prevVal);  
        setPactive((prevState) => !prevState)

        
        data.QuoteId = QuoteId;
        data.ProductId = item.productId;
        data.EnhancementId = item.enhancementId;
        data.Qty = qty; 
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/AddUpdateQuoteEnhancement`
        };
        await axios(option)
            .then((e) => {
                setisLoader(false);
                
            })
            .catch((err) => { 
            })
    }

     var addClassOnScroll = function () { 
            var windowTop = $(window).scrollTop();
            $('.prd_sect_each').each(function (index, elem) {
                var offsetTop = $(elem).offset().top;
                var outerHeight = $(this).outerHeight(true);

                if( windowTop > (offsetTop - 100) && windowTop < ( offsetTop + outerHeight)) {
                    var elemId = $(elem).attr('pid');
                    setActive(elemId)
                    
                }
            });
        };

 let location = useLocation();

    useEffect(() => {
        GetQuote();
       AOS.init({ duration: 2000 });
       let pop_status = localStorage.getItem('pop_status');
      if(pop_status){ 
        setWelcomeModal(true)
      }
       
    }, [location])
    return (
        <div className="container-fluid glass-page">
            <div className="row">
                <div className="col-md-3 col-lg-2 px-0">
                    <Sidebar />
                </div>

                <div className="col-md-9 col-lg-10 position-relative px-0">
                    <div className="main-content align-items-start py-5">
                        <div className="bg-image" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                            <img src={qouteData?.coverImagePath} alt="img" className="bg-img img-fluid" />
                        </div>
                        <div className="container position-relative h-100">
                            <div className="row cust-height-100" data-aos="fade-zoom-in"
                                data-aos-easing="ease-in-back"
                                data-aos-delay="1000"
                                data-aos-offset="0">
                                <div className="col-md-8 col-lg-9">
                                    <div className={`estimate-content-section ${isModalMinimise ? 'collapsible-shutter' : null}`}>
                                        <div className="ecs-fixed-header">
                                            <ul className="estimate-heading-list">
                                                <li className="d-lg-flex justify-content-lg-between align-items-center">
                                                    <div className="estimate-left-sec">
                                                        <h3>{qouteData?.title}</h3>
                                                        <p>Location: {qouteData?.customerAddress}</p>

                                                    </div>
                                                    <div className="estimate-right-sec">
                                                        <h3>{qouteData?.salesForceRefNo}</h3>
                                                        <p>Date added: {dateAdded}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        {qouteData && <div className="ecs-scrolling-description-sec"  onScroll={addClassOnScroll}>
                                            {
                                                qouteData?.products?.map((pro, index) => {
                                                    return (
                                                        <div key={index} pid={pro?.productId} className="prd_sect_each" id={pro?.productId+'_mainprd_sec'}>
                                                            <div className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                                                <div className="lft-sec">
                                                                    {index == 0 && <h5><b>{`To ${qouteData?.termAndCondition?.name} the following items`}</b></h5> }
                                                                </div>
                                                            </div>
                                                            <h5>{pro?.productName}</h5>
                                                            <div className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                                                <div className="lft-sec">

                                                                    <p>{pro?.productAttStr}</p>
                                                                </div>
                                                                <div className="rgt-sec">
                                                                    <h3>{`£ ${pro?.price} + ${qouteData?.vat}% (VAT)`}</h3>
                                                                </div>
                                                            </div>
                                                            <h5>Optional Extras / Enhancements</h5>
                                                            {
                                                                pro.productEnhancementArr?.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className="main-wrap-sec d-lg-flex justify-content-lg-between">
                                                                            <div className="lft-sec">

                                                                                <p>{item?.name} ({item?.perUnit}).</p>
                                                                            </div>
                                                                            <div className="rgt-sec">
                                                                                <h3>{` £ ${item?.price} + ${qouteData?.vat}% (VAT)`}</h3>
                                                                               {/*<span>{item?.qty}</span>*/}
                                                                                <div className="rgt-sec-cta">
                                                                                    {item?.uploadedFiles.length > 0 && <a onClick={() => showenhanImages(item?.uploadedFiles)} href="javascript:void(0)"><img src={EstimatesecSvg.ImgicoSvg} alt="icon" /></a>}

                                                                                    {(!addedEnsPrd.includes(item?.enhancementId+'-'+item?.productId)) &&
                                                                                        <div className="d-flex qty-wrapper"><label>QTY X </label><input disabled="act" type="number" min={1} className="enh_qty" defaultValue={(item?.qty == 0 ? pro?.qty : item?.qty)}  id={item?.enhancementId+'-'+item?.productId} /></div>

                                                                                    }

                                                                                    {(addedEnsPrd.includes(item?.enhancementId+'-'+item?.productId)) && 
                                                                                        <div className="d-flex qty-wrapper"><label>QTY X </label><input disabled={`${(cpactive ? 'act' : '')}`} onChange={() => changeQty(item)} type="number" min={1} className="enh_qty" defaultValue={(item?.qty ==0 ? pro?.qty : item?.qty)}  id={item?.enhancementId+'-'+item?.productId} /></div>

                                                                                    }


                                                                                    

                                                                                    {!cpactive && <a href="javascript:void(0)" onClick={() => saveProduct(item)} pidd={item?.enhancementId+'-'+item?.productId} className={`btn btn-primary add-btn ${(addedEnsPrd.includes(item?.enhancementId+'-'+item?.productId) ? 'active' : '')}`} role="button">{(addedEnsPrd.includes(item?.enhancementId+'-'+item?.productId) ? 'Added' : 'Add')}</a> }
                                                                                    {cpactive && <a href="javascript:void(0)" className="btn btn-primary add-btn disabled-btn" role="button">Added</a> }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            
                                                            <h5>Specification</h5>


                                                            <ul>
                                                                <li dangerouslySetInnerHTML={{ __html: pro?.specification }}></li>
                                                            </ul>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <h5>Notes </h5>
                                            <p>{qouteData?.notes}</p>
                                           
                                            <div className="total-estimate">
                                                <div className="price-count">
                                                    
                                                    <h5><b>Total:</b> <span>{`£ ${qouteData?.totalAmt+enhAddedamt} + ${qouteData?.vat}% (VAT)`}</span></h5>
                                                </div>
                                               
                                            </div>
                                        </div>}
                                        <div className="shutter-sec" onClick={() => EventMinimiser()}>
                                            <p>Expand to view your glass</p>
                                            <div className="colapsible-icon"><img src={EstimatesecSvg.ColapsibleSvg} alt="icon" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3 extra-btn-sec">
                                    <div className="popup-cta-btn justify-content-around ms-md-auto">
                                        <a href="javascript:void(0)" onClick={() => showPopImages(qouteData?.media.images)}  className="btn img-popup-btn"  role="button"><img src={EstimatesecSvg.ImgicoSvg} alt="icon" /></a>
                                        <a onClick={() => showPopVideos(qouteData?.media.videos)}  className="btn play-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle" role="button"><img src={EstimatesecSvg.PlayicoSvg} alt="icon" /></a>
                                        <a onClick={() => showPopInfo(qouteData?.media.info)} className="btn info-popup-btn" data-bs-toggle="modal" href="#exampleModalToggle3" role="button"><img src={EstimatesecSvg.InfoicoSvg} alt="icon" /></a>
                                    </div>
                                    <div className={`pagination-sec ${qouteData?.products != null && qouteData?.products.length > 5 && 'more_nav_icons'}`}>
                                        <div className="pagination-scrollable">
                                            <ul className={`pagination-list`}  >
                                             {
                                                qouteData?.products?.map((pro, index) => {
                                                    return (
                                                <li key={index}><a  className={`list-group-item ${active == null && location.state == null && index == 0 && 'active'} ${active == pro?.productId && 'active'}`} pid={pro?.productId} href="javascript:void(0)" id={pro?.productId+'_maignprd_sec'} onClick={() => slideToProd(pro?.productId)}>{index+1}</a></li>

                                                    )
                                                })

                                            }
                                            
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoader && <div className="loader-img-outer"><img src={LoaderImg} className="loader_img_cont" /> </div> }
                    </div>

                    <div className="footer-section">

              <div className="container">
                <div className="row flex-md-nowrap">
                  <div className="col d-flex align-items-center chatbot-sec py-md-5 py-3">
                    <div className="chatbot-icon">
                      <a href="#">

                        <svg xmlns="http://www.w3.org/2000/svg" width="41.456" height="41.456" viewBox="0 0 41.456 41.456">
                          <g id="Icon_ionic-ios-chatbubbles" data-name="Icon ionic-ios-chatbubbles" transform="translate(-3.375 -3.375)">
                            <path id="Path_31" data-name="Path 31" d="M39.538,30.541a2.414,2.414,0,0,1,.329-1.216,3.356,3.356,0,0,1,.209-.309,16.147,16.147,0,0,0,2.75-9c.03-9.188-7.723-16.642-17.31-16.642A17.191,17.191,0,0,0,8.546,16.619a16.014,16.014,0,0,0-.369,3.408A16.9,16.9,0,0,0,25.218,36.879a20.523,20.523,0,0,0,4.7-.767c1.126-.309,2.242-.718,2.531-.827a2.635,2.635,0,0,1,.927-.169,2.591,2.591,0,0,1,1.007.2l5.65,2a1.347,1.347,0,0,0,.389.1.793.793,0,0,0,.8-.8,1.28,1.28,0,0,0-.05-.269Z" transform="translate(2.004)" />
                            <path id="Path_32" data-name="Path 32" d="M30.331,33.73c-.359.1-.817.209-1.315.319a18.393,18.393,0,0,1-3.388.448A16.9,16.9,0,0,1,8.587,17.646a18.841,18.841,0,0,1,.149-2.133c.06-.429.13-.857.229-1.276.1-.448.219-.9.349-1.335l-.8.708A14.83,14.83,0,0,0,3.375,24.761a14.665,14.665,0,0,0,2.471,8.172c.229.349.359.618.319.8s-1.186,6.179-1.186,6.179a.8.8,0,0,0,.269.767.812.812,0,0,0,.508.179.715.715,0,0,0,.289-.06l5.591-2.2a1.557,1.557,0,0,1,1.2.02,16.772,16.772,0,0,0,6.049,1.2A15.651,15.651,0,0,0,30.85,34.318s.319-.438.688-.957C31.168,33.491,30.75,33.62,30.331,33.73Z" transform="translate(0 3.976)" />
                          </g>
                        </svg>

                      </a>
                    </div>
                  </div>
                  <div className="col d-md-flex d-none align-items-center py-md-5 py-3">
                    <div className="contact-no">
                      <h3>Contact Number<a href="#">0808 149 2159</a></h3>
                    </div>
                  </div>
                  <div className="total-estimate-sec col d-flex align-items-start py-md-5 py-3">
                    <div className="price-label">
                      <h1>Your Total: </h1>
                      <p>Subject to survey</p>
                    </div>
                    <div className="price-amount">
                      <h1>£ {(qouteData?.totalAmt+qouteData?.totalEnhancementAmt).toFixed(2)}</h1>
                      <p>Including VAT</p>
                    </div>
                  </div>



                  <div className="col d-flex justify-content-end align-items-center py-md-5 py-3">
                    <div className="cta-btn footer-cta-btn wrap d-flex">
                    <button className="button-transparent-bg border-0 bg-transparent"><Link to="/team" className="btn btn-primary prev-btn border-animation"  role="button">Back</Link></button>
                    <button className="button-lottie-ani border-0 bg-transparent">
                  <Lottie options={defaultOptions}
                    height={40}
                    width={150}
                    isStopped={false}
                    isPaused={false} /><Link to="/review" className="btn btn-primary next-btn" role="button">Next</Link>
                </button>


                      

                    </div>
                  </div>
                </div>
              </div>
            </div>

                </div>
            </div>


            <div className={`modal blur-overlay fade welcome-overlay ${welcomeModal ? 'd-none' : ''}`} data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="3000"
                data-aos-offset="0" id="welcome-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h1>Welcome</h1>
                                    <p>Here is a quick video showing you how to interact with your estimate to receive your quote.</p>
                                    <a href="javascript:void(0)" onClick={welcomeModalHandler} data-dismiss="modal" className="btn btn-primary start-now-btn" role="button">Start Now</a>
                                </div>
                                <div className="col-sm-6">
                                    <div className="welcome-video-sec">
                                        <img src={require('../../assets/images/welcome_video_popup.png')} alt="img" className="wc-video img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal blur-overlay fade finished-overlay" id="finishing-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <h1>Things to know before the next step</h1>
                                    <div className="modal-scrolable-area">
                                        <h2>Lead Time </h2>
                                        <p>Lead time is approximately 2-3 weeks for a survey, 7-10 Working days to receive you working drawings and then 14-16 weeks from the date of signed and received manufacturing drawings and should take approximately 8-9 working days to install.</p>
                                        <h2>Terms</h2>
                                        <p>40% deposit with order, 50% prior to commencement on site and balance strictly on completion.</p>
                                        <p>Any variations to contract following survey must be agreed in writing and will be itemised and added in full to the stage payment invoice due for payment prior to the commencement of installation.</p>
                                        <h2>Review and Confirm</h2>
                                        <p>On the next step you will be able to confirm your quote and make any change requests, this will confirm your price subject to survey.</p>
                                        <h2>Guarantee</h2>
                                        <p>Our products and workmanship are guaranteed for a period of 10 years from installation and are also insurance backed for your complete peace of mind.</p>
                                        <p>If you have any questions please do not hesitate to e-mail or call us on: 0800 328 3028.</p>
                                        <p>If you wish to view more images or read more on the products please visit : www.yesglazing.co.uk</p>
                                        <p>or to meet and see the products in person, please feel free to book in to visit the showroom.</p>
                                        <p>I do hope the above meets with your approval and I look forward to hearing how you wish to proceed.</p>
                                        <p>Kind regards </p>
                                        <p>— James Howard</p>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="about-video-sec">
                                        <h4>About us & Our Processes</h4>
                                        <img src={require('../../assets/images/aboutprocess01.png')} alt="img" className="wc-video img-fluid" />
                                        <img src={require('../../assets/images/aboutprocess02.png')} alt="img" className="wc-video img-fluid" />
                                        <img src={require('../../assets/images/aboutprocess03.png')} alt="img" className="wc-video img-fluid" />
                                        <a href="javascript:void(0)" className="btn btn-primary start-now-btn" role="button">continue</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal video-play-modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content white-blur-bg">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                        {videos && <div className="modal-body btn" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal">
                        <div className="row">
                            
                            {
                                videos.map((videor, index) => {
                                    return (
                                    <div key={index} className="col-md-4"><video width="250" height="210" controls>
    <source src= { videor.path } type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
    
</video></div>
                                    )
                                })

                            }
                            </div>
                            
                        </div> }
                    </div>
                </div>
            </div>
            <div className="modal fade second-popup" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ backgroundImage: `url(${background})` }}></button>
                        <div className="modal-body">
                            <img src={require('../../assets/images/pp-vdo.png')} alt="img" className="pp-video img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal blur-overlay fade info-popup-overlay" id="exampleModalToggle3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row align-items-center">
                                {infos && <div className="col-12 pdf-info-list">


                            {
                                infos.map((info, index) => {
                                    return (
                                <h5 key={index}><a href={info.path} target="_blank" className="infoDatapdf"><img width="24" src={PagesSvg.pdfIconSvg} /> {info.fileName}</a></h5>

                                    )
                                })

                            }
                                    

                                </div> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

            <div className={`modal blur-overlay fade enhnc-overlay ${enhImageModalLabel ? 'show' : 'd-none'}`} id="enh-modal" tabIndex="-1" aria-labelledby="enhModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content white-blur-bg">
 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => closeEnhModal()} style={{ backgroundImage: `url(${background})` }}></button>
                        <div className="modal-body">

                            <div className="row align-items-center">
                               <div className="col-sm-12">
                                   {enhImgesArr.length > 0 && <Carousel slides={enhImgesArr} autoplay={false} interval={5000}/> } 
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Glass;