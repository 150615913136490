import React, { useEffect, useState } from "react";
//import ReactDOM from 'react-dom/client';
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import PagesSvg from "../Constant/PagesSvg";
import background from "../../assets/images/close.svg";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import { ApiUrl, GetQuoteId } from "../../utils/utils";
import axios from "axios";
import { Link } from "react-router-dom";
import LoaderImg from "../../assets/images/loader.gif";
import moment from "moment/moment";

function Progress() {
  const QuoteId = GetQuoteId();
  const [animate, setanimate] = useState(false);
  const [animateClass, setanimateClass] = useState(false);
  const [topic, setTopic] = useState("");
  const [question, setQuestion] = useState("");
  const [topicErr, setTopicErr] = useState(false);
  const [questionErr, setQuestionErr] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [qouteData, setQouteData] = useState("");
  const [isLoader, setisLoader] = useState(false);

  const [qouteProgress, setqouteProgress] = useState(true);
  const [isContractSigned, setisContractSigned] = useState(false);
  const [isDepositPaid, setisDepositPaid] = useState(false);
  const [isSurveyBooked, setisSurveyBooked] = useState(false);
  const [iscadDrwings, setiscadDrwings] = useState(false);
  const [iscustomerSign, setiscustomerSign] = useState(false);
  const [isInstallDateSent, setisInstallDateSent] = useState(false);
  const [isInstallDate, setisInstallDate] = useState(false);
  const [isStagepament, setisStagepament] = useState(false);
  const [isinstallation, setisinstallation] = useState(false);
  const [isfinalPayment, setisfinalPayment] = useState(false);
  const [isJobComplete, setisJobComplete] = useState(false);
  const [notificationdata, setNotificationData] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    GetQuoteProgress();
    GetQuoteLogs();
    GetQuote();
    let timer = setInterval(
      () => setanimateClass("position-with-animation"),
      10
    );
    let tidmer = setTimeout(() => $(".step").removeClass(""), 40);
  }, []);

  const sendRequest = () => {
    setTopicErr(false);
    setQuestionErr(false);
    setRequestMessage("");

    if (topic == "") {
      setTopicErr(true);
      return;
    }
    if (question == "") {
      setQuestionErr(true);
      return;
    }
    const data = {};
    data.QuoteId = QuoteId;

    data.Topic = topic;
    data.Question = question;
    setTopic("");
    setQuestion("");
    setRequestMessage("Your request has been seccessfully submitted!");
  };

  const GetQuote = () => {
    const data = {};
    data.QuoteId = QuoteId;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        setQouteData(e?.data?.data);

        if (
          e?.data?.data?.quoteContractInfoModel?.isSignAccepted == true &&
          e?.data?.data?.contractFilePath != ""
        ) {
        }
      })
      .catch((err) => {
      });
  };

  const GetQuoteLogs = () => {
    const data = {};
    data.QuoteId = QuoteId;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/QuoteNotification`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        setNotificationData(e?.data?.data);
      })
      .catch((err) => {
      });
  };

  const GetQuoteProgress = () => {
    const data = {};
    data.QuoteId = QuoteId;
    const option = {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "content-type": "application/json",
        api_key: "B616VV5-7ED4Q7B-MH2562J-ED281YB",
      },
      data: data,
      url: `${ApiUrl()}/v1/Quote/QuoteProgressJourney`,
    };
    axios(option)
      .then((e) => {
        setisLoader(false);
        setqouteProgress(true);
        if (e?.data?.data?.isContractSigned) {
          setisContractSigned(true);
          if (e?.data?.data?.isDepositPayed) {
            setisDepositPaid(true);
            if (e?.data?.data?.isSurveyAppointmentConfirmed) {
              setisSurveyBooked(true);
              if (e?.data?.data?.isCADDrawingsInProgress) {
                setiscadDrwings(true);
                if (e?.data?.data?.isCustomerSignOffDocumentsSent) {
                  setiscustomerSign(true);
                  if (e?.data?.data?.isInstallationDateSent) {
                    setisInstallDateSent(true);
                    if (e?.data?.data?.isInstallationDateConfirmed) {
                      setisInstallDate(true);
                      if (e?.data?.data?.isStagePaymentPayed) {
                        setisStagepament(true);
                        if (e?.data?.data?.isInstallationDay) {
                          setisinstallation(true);
                          if (e?.data?.data?.isFinalPayment) {
                            setisfinalPayment(true);
                            if (e?.data?.data?.isJobCompleted) {
                              setisJobComplete(true);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
      .catch((err) => {
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-lg-2 px-0">
          <Sidebar />
        </div>
        <div className="col-md-9 col-lg-10 payment-page position-relative">
          <div className="main-content align-items-start py-5">
            <div className="bg-image">
              {" "}
              <img
                src={qouteData?.coverImagePath}
                alt="img"
                className="bg-img img-fluid"
              />
            </div>
            <div className="bg_prrogress">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="heading-section">
                      <h1>Progress</h1>
                      <p className="p_progressname">
                        {" "}
                        {qouteData.customerName}, Here you'll be able to see
                        your progress with your order. <br />
                        If you have any questions, please contact{" "}
                        {qouteData.salesPersonEmail}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 position-relative">
                    <div className="popup-cta-btn print-info-btn ms-auto">
                      <div className="info-btn-sec">
                        <a
                          className="btn info-popup-btn"
                          data-bs-toggle="modal"
                          href="#exampleModalToggle2"
                          role="button"
                        >
                          <img src={PagesSvg.InfoSvg} alt="icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row my-5">
                  <div className="col-12">
                    {qouteProgress && (
                      <div className="justify-content-center progress-step-main">
                        <div
                          className={
                            "position-relative progress-step-active " +
                            (animateClass != ""
                              ? animateClass
                              : "position-without-animation")
                          }
                        >
                          <div className="new_cont">
                            <div
                              className={`qoutefirst_section step step-one ${isContractSigned ? "active" : "inactive"
                                } ${isContractSigned && !isDepositPaid
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>Contract signed</h5>
                              </div>
                            </div>
                            <div
                              className={`step step-two ${isDepositPaid ? "active" : "inactive"
                                } ${isDepositPaid && !isSurveyBooked
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                                <h5>Deposit paid</h5>
                              </div>
                            </div>
                            <div
                              className={`step step-three ${isSurveyBooked ? "active" : "inactive"
                                } ${isSurveyBooked && !iscadDrwings
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                                <h5>Survey booked</h5>
                              </div>
                            </div>

                            <div
                              className={`step step-four ${iscadDrwings ? "active" : "inactive"
                                } ${iscadDrwings && !iscustomerSign
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                                <h5>
                                  CAD Drawings In progress (awaiting the CAD
                                  owner)
                                </h5>
                              </div>
                            </div>
                            <div
                              className={`step step-five ${iscustomerSign ? "active" : "inactive"
                                } ${iscustomerSign && !isInstallDateSent
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>
                                  Customer sign off <br /> documents sent
                                </h5>
                              </div>
                            </div>
                            <div
                              className={`step step-six ${isInstallDateSent ? "active" : "inactive"
                                } ${isInstallDateSent && !isInstallDate
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>
                                  Install date sent (awaiting customers
                                  response)
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12">
                    {qouteProgress && (
                      <div className="justify-content-center progress-step-main">
                        <div
                          className={
                            "position-relative progress-step-active " +
                            (animateClass != ""
                              ? animateClass
                              : "position-without-animation")
                          }
                        >
                          <div className="new_cont bottom_animationb">
                            <div
                              className={`step step-seven ${isInstallDateSent && !isInstallDate
                                ? "six_active"
                                : ""
                                }   ${isInstallDate ? "active " : "inactive"
                                } ${isInstallDate && !isStagepament
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>Install date agreed showing date</h5>
                              </div>
                            </div>
                            <div
                              className={`step step-eight ${isInstallDate && !isStagepament
                                ? "seven_active"
                                : ""
                                }  ${isStagepament ? "active " : "inactive"
                                } ${isStagepament && !isinstallation
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>
                                  Stage payment (awaiting customers payment)
                                </h5>
                              </div>
                            </div>
                            <div
                              className={`step step-nine  ${isStagepament && !isinstallation
                                ? "eight_active"
                                : ""
                                } ${isinstallation ? "active " : "inactive"
                                } ${isinstallation && !isfinalPayment
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>Installation day</h5>
                              </div>
                            </div>
                            <div
                              className={`step step-ten  ${isinstallation && !isfinalPayment
                                ? "nine_active"
                                : ""
                                } ${isfinalPayment ? "active " : "inactive"
                                } ${isfinalPayment && !isJobComplete
                                  ? "delivery-truck"
                                  : ""
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>Final Payment</h5>
                              </div>
                            </div>
                            <div
                              className={`step step-eleven ${isfinalPayment && !isJobComplete
                                ? "ten_active"
                                : ""
                                } ${isJobComplete
                                  ? "active  job_active"
                                  : "inactive"
                                }`}
                            >
                              <div className="step-inner">
                                <div className="step-img">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="51"
                                    height="51"
                                    viewBox="0 0 51 51"
                                  >
                                    <g
                                      id="step-graphic"
                                      transform="translate(-488 -421)"
                                    >
                                      <circle
                                        id="Ellipse_25"
                                        data-name="Ellipse 25"
                                        cx="25.5"
                                        cy="25.5"
                                        r="25.5"
                                        transform="translate(488 421)"
                                        fill="#62bbbc"
                                      />
                                      <g id="Group_86" data-name="Group 86">
                                        <line
                                          id="Line_25"
                                          data-name="Line 25"
                                          x2="10"
                                          y2="8"
                                          transform="translate(501.5 448.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                        <line
                                          id="Line_26"
                                          data-name="Line 26"
                                          x1="14"
                                          y2="20"
                                          transform="translate(511.5 436.5)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeWidth="6"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </div>

                                <h5>Job Complete</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* third row start */}

                  {/* third row end */}
                </div>
                <div className="row my-5 d-none">
                  <div className="col-md-6 pe-md-5">
                    <div className="notifications-sec">
                      <h3>Notifications</h3>
                      <ul className="ps-0">
                        {notificationdata?.map((notification, index) => {
                          return (
                            <li key={index} className="d-flex justify-content-between align-items-center">
                              <h3 className="noti-label">
                                {notification?.notificationText}
                              </h3>
                              <h3 className="noti-date">
                                {moment(notification?.createdOn)?.format(
                                  "DD/MM/YY"
                                )}
                              </h3>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isLoader && (
              <div className="loader-img-outer">
                <img src={LoaderImg} className="loader_img_cont" />{" "}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal blur-overlay fade info-popup-overlay"
        id="exampleModalToggle2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <h5>
                    Stonly.com <br />
                    Support info
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Progress;
